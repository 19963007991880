import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import Table from "../../components/Table";
import { convertToLocal } from "../../config/functions";
import useAuth from "../../hooks/useAuth";
import useContact from "../../hooks/useContact";
import Model from "../../components/model"
let listStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  padding: '6px 0px'
}
export default function Default() {
  const {
    loading,
    contacts,
    deleteLoading,
    page, setPage,
    limit, setLimit,
    getContacts,
    deleteContactt,
    filter, setfilter
  } = useContact()
  const navigator = useNavigate();

  useEffect(() => {
    getContacts();
  }, [page, limit, filter]);
  const { isAdmin } = useAuth();

  const labels = [
    "#",
    "Name",
    "Company",
    "Email",
    "Telephone",
    "Mobile",
    "Created At",
    isAdmin && "Actions",
  ].filter((item) => item);
  const [show, setShow] = useState(null)
  const data = useMemo(() => {
    if (!contacts || contacts.length === 0) return [];
    return contacts.map((item, index) => {
      return {
        data: item,
        fields: [
          index + 1,
          item?.name,
          <td>{item.companyID?.name}</td>,
          <td>{item?.email}</td>,
          <td>{item?.phone}</td>,
          <td>{item?.mobile}</td>,
          convertToLocal(item?.createdAt),
          isAdmin && (
            <div className="d-flex gap-3">
              {
                <i
                  className="bi bi-eye text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShow(item)
                  }}
                ></i>
              }
              {
                <i
                  className="bi bi-pencil-square text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator(`/add-contact?edit=true`, {
                      state: { ...item },
                    });
                  }}
                ></i>
              }
              {item?._id == deleteLoading ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={deleteLoading != null} color={"red"} />
                </div>
              ) : (
                <i
                  className="bi bi-trash text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteContactt(item._id)}
                ></i>
              )}
            </div>
          ),
        ].filter((it) => it),
      };
    });
  }, [contacts, deleteLoading]);
  return (
    <Screen
      filter={filter}
      setFilter={setfilter}
      loading={loading == "initial"}
      flow={[
        { label: `Contacts`, to: `/contacts` },
        { label: "Home", to: "/" },
        { label: `Contacts`, to: `/contacts` },
      ]}
    >
      <Model title="Contact Details" show={show} handleClose={() => setShow(null)}>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Name</Typography>
          <Typography>{show?.name}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Company</Typography>
          <Typography>{show?.companyID?.name || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
          <Typography>{show?.email}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Telephone</Typography>
          <Typography>{show?.phone || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Mobile</Typography>
          <Typography>{show?.mobile || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Address</Typography>
          <Typography>{show?.address || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Description</Typography>
          <Typography>{show?.description || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Date added</Typography>
          <Typography>{convertToLocal(show?.createdAt) || 'None'}</Typography>
        </Box>
      </Model>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: "20px",
        }}
      >
        <SubmitButton
          title={"Add Contact"}
          onClick={() => {
            navigator(`/add-contact`);
          }}
          color={"primary"}
        />
      </Box>
      <Table
        labels={labels}
        data={data}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      // totalData={totalMsDocs}
      />
    </Screen>
  );
}
