import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {
    Box,
    Card,
    Typography
} from "@mui/material";
import { default as React } from 'react';
import SubscriptionBullet from "../../components/subscriptionBullet";
import useAuth from '../../hooks/useAuth';
export default function Default({ item, onClick, buttonText,showValidity, disabled, style }) {
    const { isCompanyAdmin, user } = useAuth()
    return (
        <Card
            sx={{
                borderRadius: "10px",
                border:
                    user.subscriptionID?._id == item._id &&
                    "3px solid #0d6efd",
                ...style
            }}
            className="aos-init aos-animate"
            data-aos="zoom-in"
            data-aos-delay="200"
        >
            <Box sx={{ padding: "35px 30px 20px 5px" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h4 className="title" style={{ color: "#0d6efd" }}>
                        {item.name}
                    </h4>
                </Box>
                {!isCompanyAdmin ? <ul>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Feature</Typography>
                        <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Allowance</Typography>
                    </Box>
                    {Object.keys(item).map((item1) => {
                        if (
                            item1 != "_id" &&
                            item1 != "name" &&
                            item1 != "cost" &&
                            item1 != "updatedAt" &&
                            item1 != "createdAt" &&
                            item1 != "priceID" &&
                            item1 != "validity" &&
                            item1 != "discountPercent" &&
                            item1 != "__v" &&
                            item1 != "additionalFeatures"
                        ) {
                            return (
                                <SubscriptionBullet
                                    itemKey={item1}
                                    value={item[item1]}
                                />
                            );
                        }
                    })}
                    <SubscriptionBullet
                        itemKey={"Chat"}
                        value={item?.allowedInbox ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                    />
                    <SubscriptionBullet
                        itemKey={"Management System"}
                        value={item?.allowedManagementSystem ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                    />
                    <SubscriptionBullet
                        itemKey={"Access to Checklist Templates"}
                        value={item?.allowedCLTemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                    />
                    <SubscriptionBullet
                        itemKey={"Access to Policy Templates"}
                        value={item?.allowedPOTemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                    />
                    <SubscriptionBullet
                        itemKey={"Access to RA Templates"}
                        value={item?.allowedRATemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                    />
                    <SubscriptionBullet
                        itemKey={"Access to Audit Templates"}
                        value={item?.allowedATTemplates ? <DoneIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                    />
                </ul> :
                    <ul>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Feature</Typography>
                            <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>Allowance</Typography>
                        </Box>
                        {user?.subscriptionID?.additionalFeatures?.map((item1, index) => (
                            <SubscriptionBullet
                                key={index}
                                itemKey={item1.feature}
                                value={item1.allocation}
                            />
                        ))}
                    </ul>}
            </Box>
            <Box sx={{ padding: "10px 20px 35px 20px" }}>
                {showValidity ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            color: "#0d6efd",
                            fontWeight: "bold",
                        }}
                    >
                        {"Valid Till: "}
                    </Typography>
                    <Typography
                        sx={{
                            color: "#0d6efd",
                            paddingLeft: '5px'
                            // fontWeight: "bold",
                        }}
                    >
                        {` ${user.endDate?.substring(0, 10)}`}
                    </Typography>
                </Box> : <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#0d6efd",
                        fontWeight: "bold",
                    }}
                >
                    Cost: £{item.cost} / Month{" "}
                    {item.validity == "yearly" && "(2 Months free)"}
                </Box>}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <button
                        className={`btn btn-sm rounded-5 btn-outline-primary px-5`}
                        disabled={disabled}
                        onClick={onClick}
                    >
                        {buttonText}
                    </button>
                </Box>
            </Box>
        </Card>
    )
}
