import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import FormField from "../../components/FormField";
import Loader from "../../components/Loader";
import PaymentModel from "../../components/paymentModel";
import Screen from "../../components/Screen";
import useAuth from "../../hooks/useAuth";
import usePaymentMethod from "../../hooks/usePaymentMethod";
import useSubscription from "../../hooks/useSubscription";
import SubscriptionCard from "./subscriptionCard";
// import "./index.css";
export default function Default() {
  const { subscriptions } = useSelector((state) => state.subscription);
  const { paymentMethods } = useSelector((state) => state.paymentMethods);
  const stripe = useStripe();
  const { user } = useAuth();
  const [promoCode, setPromoCode] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [query] = useSearchParams();
  const { getPaymentMethods } = usePaymentMethod();
  let _id = query.get("id");
  const {
    getData,
    stripeLoading,
    filter,
    setfilter,
    loading,
    error,
    setError,
    showPaymentModel,
    setShowPaymentModel,
    handleSubmit,
    selectedSubscription,
    setSelectedSubscription
  } = useSubscription();
  useEffect(() => {
    getData(true, _id);
    setError(null);
    getPaymentMethods();
  }, []);
  const [view, setView] = useState("monthly"); // Default view
  const [showCurrent, setshowCurrent] = useState(true);
  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView); 
    }
  };
  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setfilter}
      flow={[
        { label: "Subscriptions", to: "/subscription" },
        { label: "Home", to: "/" },
        { label: "Subscriptions", to: "/subscription" },
      ]}
    >
      {!_id &&
        user.subscriptionID?.name != "Basic" &&
        showCurrent &&
        user.subscriptionID?.name != "BASIC +" &&
        user.subscriptionID?.name != "BRONZE" &&
        user.subscriptionID?.name != "SILVER" &&
        user.subscriptionID?.name != "GOLD" && (
          <>
            <center
              style={{
                backgroundColor: "#FFFFE0",
                padding: "7px 0px",
                borderRadius: "10px",
                border: "1px solid #FFD700",
              }}
            >
              <Typography>
                <b>Note: </b> Your plan is management by super admin. Contact
                super admin to change your plan.
              </Typography>
            </center>
            <br />
          </>
        )}
      {paymentMethods.length < 1 && (
        <PaymentModel
          show={showPaymentModel}
          setShow={() => setShowPaymentModel(false)}
          header={"Card Payment"}
          onSubmit={handleSubmit}
          error={error}
          stripe={stripe}
          stripeLoading={stripeLoading}
          user={user}
          _id={_id}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
        />
      )}
        <Modal
          show={showConfirmation}
          centered
          onHide={() => setShowConfirmation(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Payment Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Typography>You are about to pay <b>£{selectedSubscription?.cost}</b> for subscription?</Typography>
            <FormField item={{
              placeholder: "Promo code",
              value: promoCode,
              setValue: (value) => setPromoCode(value),
              className: "mt-2"
            }} />
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <button
                className="btn btn-success my-4"
                onClick={() => setShowConfirmation(false)}
              >
                <div className={"d-flex flex-row justify-content-center"}>
                  {<span>Cancel</span>}
                </div>
              </button>
              <button
                className="btn btn-success my-4"
                onClick={(e) => handleSubmit(e, user, _id, selectedSubscription, promoCode)}
              >
                <Loader loading={stripeLoading} color={"white"}>
                  <div className={"d-flex flex-row justify-content-center"}>
                    {<span>{"Pay Now"}</span>}
                  </div>
                </Loader>
              </button>
            </Box>
          </Modal.Body>
        </Modal>

      {!showCurrent && !_id && (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}
        >
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view-toggle"
          >
            <ToggleButton
              value="monthly"
              aria-label="monthly"
              sx={{
                borderRadius: "50px", // Oval shape
                padding: "8px 20px", // Make the button larger
                "&.Mui-selected": {
                  backgroundColor: "#0d6efd", // Blue background for selected tab
                  color: "white", // White text for selected tab
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: "#f0f0f0", // Light gray background for unselected
                  color: "black", // Default text color for unselected tab
                },
                transition: "background-color 0.3s ease", // Smooth transition for background color change
              }}
            >
              Monthly
            </ToggleButton>
            <ToggleButton
              value="yearly"
              aria-label="yearly"
              sx={{
                borderRadius: "50px", // Oval shape
                padding: "8px 20px", // Make the button larger
                "&.Mui-selected": {
                  backgroundColor: "#0d6efd", // Blue background for selected tab
                  color: "white", // White text for selected tab
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: "#f0f0f0", // Light gray background for unselected
                  color: "black", // Default text color for unselected tab
                },
                transition: "background-color 0.3s ease", // Smooth transition for background color change
              }}
            >
              Yearly (Get 2 Months free)
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      {showCurrent && !_id && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <Typography variant="h5">My Current Plan</Typography>
        </Box>
      )}
      {showCurrent && !_id && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SubscriptionCard
            item={user.subscriptionID}
            onClick={() => setshowCurrent(false)}
            disabled={user.subscriptionID?.name != "Basic" &&
              user.subscriptionID?.name != "BASIC +" &&
              user.subscriptionID?.name != "BRONZE" &&
              user.subscriptionID?.name != "SILVER" &&
              user.subscriptionID?.name != "GOLD"}
            buttonText={"Change Plan"}
            showValidity={true}
            style={{
              width: {
                xs: "90%",
                sm: "90%",
                md: "40%",
                lg: "40%",
              },
            }}
          />
        </Box>
      )}

      {((!showCurrent && !_id) || _id) && (
        <Grid container spacing={2}>
          {subscriptions?.map(
            (item, index) =>
              (item.validity == view || _id) && (
                <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
                  <SubscriptionCard
                    item={item}
                    onClick={(e) => {
                      if (paymentMethods.length < 1) {
                        setShowPaymentModel(true);
                        setSelectedSubscription(item);
                      } else {
                        e.preventDefault()
                        setShowConfirmation(true);
                        setSelectedSubscription(item)
                        // handleSubmit(e, user, _id, item)
                      }
                      // setSelectedSubscription(item);
                    }}
                    disabled={user.subscriptionID?._id == item._id ||
                      (user.subscriptionID?.name != "Basic" &&
                        user.subscriptionID?.name != "BASIC +" &&
                        user.subscriptionID?.name != "BRONZE" &&
                        user.subscriptionID?.name != "SILVER" &&
                        user.subscriptionID?.name != "GOLD" &&
                        !_id)}
                    buttonText={user.subscriptionID?._id == item._id
                      ? "Current Plan"
                      : _id
                        ? "Pay Now"
                        : "Change Plan"}
                    showValidity={false}
                    style={{}}
                  />
                </Grid>
              )
          )}
        </Grid>
      )}
    </Screen>
  );
}
