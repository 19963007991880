import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Screen from "../../components/Screen";
import useCompanies from "../../hooks/useCompanies";
import useCompanyAdmin from "../../hooks/useCompanyAdmin";
import useDynamicSubscription from "../../hooks/useDynamicSubscription";
import AdminSubscription from "./adminSubscription";
import CompanySubscription from "./companySubscription";
export default function Default() {
  const {
    loading,
    data, setData,
    onAddCompanySubscription,
    onAddAdminSubscription,
    onUpdateCompanySubscription,
    onUpdateAdminSubscription
  } = useDynamicSubscription()
  const { companies, fetchCompanies } = useCompanies();
  const { companyAdmins, fetchAdmins } = useCompanyAdmin();
  const location = useLocation()
  const [query] = useSearchParams()
  const selection = query.get('for')
  const edit = query.get('edit')
  useEffect(() => {
    if (location.state) {
      setData(location.state)
    }
    fetchCompanies();
    fetchAdmins();
  }, []);
  return (
    <Screen
      loading={loading == 'initial'}
      flow={[
        { label: `Create ${selection == 'company' ? 'Company' : 'Admin'} Subscription`, to: `/create-subscription` },
        { label: "Home", to: "/checklists" },
        { label: `Create ${selection == 'company' ? 'Company' : 'Admin'} Subscription`, to: `/create-subscription` },
      ]}
    >
      <CompanySubscription
        selection={selection}
        data={data}
        setData={setData}
        onAddSubscription={onAddCompanySubscription}
        onUpdateSubscription={onUpdateCompanySubscription}
        companies={companies}
        FormField={FormField}
        loading={loading}
        state={location.state}
        edit={edit}
      />
      <AdminSubscription
        selection={selection}
        data={data}
        setData={setData}
        onAddSubscription={onAddAdminSubscription}
        onUpdateAdminSubscription={onUpdateAdminSubscription}
        companyAdmins={companyAdmins}
        FormField={FormField}
        loading={loading}
        state={location.state}
        edit={edit}
      />
    </Screen>
  );
}

export const FormField = ({ field }) => {
  const onChange = (e) => field.setValue(e.target.value);

  return (
    <div className="row mb-3">
      <label htmlFor={field.id} className="col-md-4 col-lg-3 col-form-label">
        {field.label}
        {field.required ? <span className="text-danger"> *</span> : null}
      </label>
      <div className="col-md-8 col-lg-9">
        {field.type == "longtext" ? (
          <textarea
            name={field.id}
            className="form-control"
            id={field.id}
            style={{ height: 100 }}
            onChange={onChange}
            value={field.value}
            disabled={field.disabled}
          />
        ) : field.type == "dropdown" ? (
          <>
            <select
              id={field.id}
              value={field.value}
              className="form-select"
              onChange={onChange}
              disabled={field.disabled}
            >
              <option
                selected={
                  field?.value == "" ||
                  field.value == null ||
                  field.value == undefined
                }
                value={""}
              >
                {`-- ${field.placeholder} --`}
              </option>
              {field?.options?.map((val, index) => {
                const value = !val.value ? val._id : val.value;
                const display = !!val.name ? val.name : val;
                return (
                  <option
                    key={index}
                    selected={field?.value == value}
                    value={value}
                  >
                    {display}
                  </option>
                );
              })}
            </select>
          </>
        ) : field.type == 'radio' ? (
          <fieldset className="row mb-3">
            <div className="col-sm-12">
              {field?.options.map((option, index) => (
                <div className="form-check" key={index}>
                  <input
                    disabled={field.disabled}
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios1"
                    value={option}
                    checked={option == field?.value}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="gridRadios1">
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        ) : (
          <input
            name={field.id}
            type={field.type}
            className="form-control"
            id={field.id}
            value={field.value}
            onChange={onChange}
            placeholder={field.placeholder}
            disabled={field.disabled}
          />
        )}
      </div>
    </div>
  );
};
