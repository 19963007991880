import { Checkbox } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../app/api";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { keys } from "../../config/keys";
import { AgreementContext } from "../../hooks/agreementContext";
import useAuth from "../../hooks/useAuth";
import useAgreement from "../../hooks/useAgreement";
import PDF from "./pdf";
import DownloadButton from "../../components/downloadButton"
import { convertToLocal } from "../../config/functions";
const FilesList = ({ template, limit, page, setPage, setLimit }) => {
  const { agreements } = useSelector((state) => state.Agreement);
  const { deleteAgreement, loading, getAgreements } = useAgreement();
  const { isAdmin, isCompany, isManager } = useAuth();
  const { selectedForShare, addForShare, removeFromShare } =
    useContext(AgreementContext);
  const navigator = useNavigate();
  const onView = (item) => {
    // window.open(`${keys.BASE_LOCAL_URL}/${item.url}`, "_blank", "noreferrer");
  };

  const labels = [
    "#",
    "Title",
    // isAdmin ? "Company" : null,
    // "Last Reviewed",
    "Created at",
    "File",
    (isAdmin || isCompany) && !template && "Actions",
  ].filter((item) => item);
  const data = useMemo(() => {
    return agreements?.map((item, index) => {
      return {
        data: item,
        fields: [
          index + 1,
          item?.title,
          // isAdmin ? (
          //   <td>{item.companyID?._id ? item.companyID?.name : "N/A"}</td>
          // ) : null,
          convertToLocal(item?.createdAt),
          // <Checkbox
          //   checked={item.isReviewed}
          //   disabled={item.isReviewed || isManager || template}
          //   onChange={async (e) => {
          //     try {
          //       if (e.target.checked) {
          //         await api.put(
          //           `${keys.BASE_LOCAL_URL}/agreements/updateReviewed`,
          //           { _id: item._id }
          //         );
          //         getAgreements();
          //       }
          //     } catch (error) { }
          //   }}
          // />,
          template ? (
            <button
              onClick={() => {
                if (template) {
                  navigator("/agreementInput", { state: item });
                } else {
                  onView(item);
                }
              }}
              className="btn btn-sm btn-success"
            >
              Select
            </button>
          ) : (
            <DownloadButton
              PDF={<PDF data={item} />}
              item={item}
              fileName={item?.title + `.pdf`}
            />
          ),

          (isAdmin || isCompany) && !template && (
            <div className="d-flex gap-3">
              {/* <i
                className="bi bi-pencil-square text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => navigator(`/agreementInput?id=${item._id}`)}
              ></i> */}
              {loading?._id == item?._id ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={loading} color={"red"} />
                </div>
              ) : (
                <i
                  className="bi bi-trash text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteAgreement(item?._id)}
                ></i>
              )}
            </div>
          ),
        ].filter((it) => it),
      };
    });
  }, [agreements, selectedForShare, template, loading]);

  return (
    <>
      <Table
        labels={labels}
        data={data}
        limit={limit}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
      />

    </>
  );
};

export default FilesList;
// Component to handle conditional PDF download link
const DownloadPDFButton = ({ item }) => {
  const [showLink, setShowLink] = useState(false);

  return (
    <>
      {!showLink ? (
        <button
          onClick={() => setShowLink(true)}
          className="btn btn-sm btn-primary"
        >
          Download PDF
        </button>
      ) : (
        <DownloadButton
          PDF={<PDF data={item} />}
          item={item}
          fileName={item?.title + `.pdf`}
        />
        // <PDFDownloadLink
        //   document={<PDF data={item} />}
        //   fileName={item?.title + `.pdf`}
        // >
        //   {({ loading }) =>
        //     loading ? (
        //       <button className="btn btn-sm btn-primary">Loading...</button>
        //     ) : (
        //       <button className="btn btn-sm btn-primary">Download PDF</button>
        //     )
        //   }
        // </PDFDownloadLink>
      )}
    </>
  );
};