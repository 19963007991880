// Public Pages
import Login from "../features/auth/Login";
import Signup from "../features/auth/Signup";
import Forgot from "../features/auth/Forgot";
import Reset from "../features/auth/Reset";
import Confirmation from "../features/auth/Confirmation";

// Protected Pages
import Dashboard from "../features/dashboard/Dashboard";
import Training from "../features/training/Training";

// Checklist
import Collection from "../features/checklist/Collection";
import NewChecklist from "../features/checklist/NewChecklist";

// Audit
import AuditCollection from "../features/audit/Collection";
import NewAudit from "../features/audit/NewAudit";

// Risk Assesment
import RiskAssesmentCollection from "../features/riskAssesment/Collection"
import NewRiskAssesment from "../features/riskAssesment/NewRiskAssesment"

// Tasks
import Tasks from "../features/tasks/Tasks";
import Task from "../features/tasks/Task";
import PDF from "../features/tasks/PDF";

// Admin
import Documents from "../features/documents/Documents";
import ManagementSystem from "../features/managementSystem";
import Records from "../features/records/Records";
import Companies from "../features/companies/Companies";
import Categories from "../features/category/Categories";
import Natures from "../features/nature/Natures";
import Admins from "../features/admin/Admins";
import CompanyAdmins from "../features/companyAdmin/Admins";
import FellowAdmins from "../features/fellowAdmin/fellowAdmins";
import Profile from "../features/profile/Profile";
import Branches from "../features/companies/Branches";
import Subscription from "../features/subscription";

// Communication
import Broadcasts from "../features/broadcast/Broadcasts";
import BroadcastDetails from "../features/broadcast/BroadcastDetails";
import Chat from "../features/chat/Chat";
import Email from "../features/emails";
import Share from "../features/share";
import ShareView from "../features/shareView"
import ShareSubmission from "../features/shareSubmissions"

// Policies
import Policies from "../features/policies";
import PoliciesInput from "../features/policies/policiesInput";
import PoliciesOptions from "../features/policies/chooseOptiontoAdd";

// Policies
import Agreement from "../features/agreement";
import AgreementInput from "../features/agreement/agreementInput";
import AgreementOptions from "../features/agreement/chooseOptiontoAdd";

// subscription
import CreateSubscription from "../features/dynamicSubscription"
import PaymentMethod from "../features/paymentMethod"
import SubscriptionOptions from "../features/dynamicSubscription/newOptions"
import SubscriptionList from "../features/dynamicSubscription/subscriptionList"
import AddProposal from "../features/proposals/addProposal"
import ListProposal from "../features/proposals/proposalList"
import AddInvoice from "../features/invoices/addInvoice"
import ListInvoice from "../features/invoices/invoiceList"
import AddCoupon from "../features/coupon/addCoupon"
import ListCoupon from "../features/coupon/list"

import CrmCollection from "../features/crmAccount/collection"
import AddAccount from "../features/crmAccount/addAccount"

import ContactCollection from "../features/contact/collection"
import AddContact from "../features/contact/addContact"

import CrmTask from "../features/crmTask/collection"
import AddCrmTask from "../features/crmTask/addTask"

import FormList from "../features/form"
import AddForm from "../features/form/newForm"
import FormSubmission from "../features/form/formSubmission"
import ConnectStripe from "../features/connectStripe"
export const protectedRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/training", element: <Training /> },
  { path: "/checklists", element: <Collection /> },
  { path: "/new-checklist", element: <NewChecklist /> },
  { path: "/audits", element: <AuditCollection /> },
  { path: "/new-audit", element: <NewAudit /> },
  { path: "/tasks", element: <Tasks /> },
  { path: "/risk-assesments", element: <RiskAssesmentCollection /> },
  { path: "/new-risk-assesment", element: <NewRiskAssesment /> },

  { path: "/task", element: <Task /> },
  { path: "/pdf", element: <PDF /> },
  { path: "/documents", element: <Documents /> },
  { path: "/records", element: <Records /> },
  { path: "/companies", element: <Companies /> },
  { path: "/branches", element: <Branches /> },
  { path: "/categories", element: <Categories /> },
  { path: "/natures", element: <Natures /> },
  { path: "/admins", element: <Admins /> },
  { path: "/companyAdmins", element: <CompanyAdmins /> },
  { path: "/fellowAdmins", element: <FellowAdmins /> },
  { path: "/profile", element: <Profile /> },
  { path: "/create-subscription", element: <CreateSubscription /> },
  { path: "/payment-method", element: <PaymentMethod /> },

  { path: "/broadcast", element: <Broadcasts /> },
  { path: "/broadcast-details", element: <BroadcastDetails /> },
  { path: "/inbox", element: <Chat /> },
  { path: "/policy", element: <Policies /> },
  { path: "/policyInput", element: <PoliciesInput /> },
  { path: "/policies-options", element: <PoliciesOptions /> },
  { path: "/agreement", element: <Agreement /> },
  { path: "/agreementInput", element: <AgreementInput /> },
  { path: "/agreement-options", element: <AgreementOptions /> },
  { path: "/email", element: <Email /> },
  { path: "/share", element: <Share /> },
  { path: "/sentMails", element: <ShareSubmission /> },
  { path: "/subscription", element: <Subscription /> },
  { path: "/subscription-options", element: <SubscriptionOptions /> },
  { path: "/management-system", element: <ManagementSystem /> },
  { path: "/subscription-list", element: <SubscriptionList /> },
  { path: "/add-proposal", element: <AddProposal /> },
  { path: "/list-proposal", element: <ListProposal /> },
  { path: "/add-invoice", element: <AddInvoice /> },
  { path: "/list-Invoice", element: <ListInvoice /> },
  { path: "/connect-stripe", element: <ConnectStripe /> },
  { path: "/add-coupon", element: <AddCoupon /> },
  { path: "/list-coupon", element: <ListCoupon /> },

  { path: "/add-form", element: <AddForm /> },
  { path: "/form-list", element: <FormList /> },
  { path: "/form-submission", element: <FormSubmission /> },
  
  
  
  { path: "/crm-collection", element: <CrmCollection /> },
  { path: "/add-crm-account", element: <AddAccount /> },
  
  { path: "/crm-to-do", element: <CrmTask /> },
  { path: "/add-crm-to-do", element: <AddCrmTask /> },

  { path: "/contacts", element: <ContactCollection /> },
  { path: "/add-contact", element: <AddContact /> },
];

export const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/forgot", element: <Forgot /> },
  { path: "/Reset", element: <Reset /> },
  { path: "/confirmation", element: <Confirmation /> },
  { path: "/shareView", element: <ShareView /> },
];
