import { createSlice } from "@reduxjs/toolkit";

const crmTaskSlice = createSlice({
  name: "crmTask",
  initialState: {
    crmTask: [],
  },
  reducers: {
    setCrmTask: (state, action) => {
      state.crmTask = action.payload;
    },
    updateCrmTask: (state, action) => {
        state.crmTask = state.crmTask.map(item => item._id == action.payload._id ? action.payload : item);
    },
    deleteCrmTask: (state, action) => {
        state.crmTask = state.crmTask.filter(item => item._id != action.payload._id);
    },
    addCrmTask: (state, action) => {
        state.crmTask = [...state.crmTask, action.payload];
    },
  },
});

export const { setCrmTask, updateCrmTask,deleteCrmTask, addCrmTask } = crmTaskSlice.actions;

export default crmTaskSlice.reducer;
