import { createContext, useState } from "react";

import React from "react";

const AgreementContext = createContext();

function AgreementState(props) {
  const [selectedForShare, setSelectedForShare] = useState([]);
  const addForShare = (item) => {
    setSelectedForShare((prevState) => {
      let newState = [...prevState];
      newState.push(item);
      return newState;
    });
  };
  const addAllToShare = (data) => {
    setSelectedForShare(data);
  };
  const removeFromShare = (item) => {
    setSelectedForShare((prevState) => {
      let newState = [...prevState];
      let updatedState = newState.filter((i) => i._id != item._id);
      return updatedState;
    });
  };
  const removeAllFromShare = () => {
    setSelectedForShare([]);
  };
  return (
    <AgreementContext.Provider
      value={{
        selectedForShare,
        setSelectedForShare,
        addForShare,
        removeFromShare,
        removeAllFromShare,
        addAllToShare
      }}
    >
      {props.children}
    </AgreementContext.Provider>
  );
}
export { AgreementContext, AgreementState };
