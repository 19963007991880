import React from 'react'
import Screen from '../../components/Screen'
import AgreementAddOptions from "./agreementAddOptions"
export default function Default() {
  return (
    <Screen flow={[
        { label: "New Agreement", to: "/newAgreementOption" },
        { label: "Home", to: "/" },
        { label: "New Agreement", to: "/newAgreementOption" },
      ]}>
        <AgreementAddOptions />
    </Screen>
  )
}
