import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../app/api";
import { DeleteAlert } from "../components/Swal Alert";
import useAlert from "./useAlert";
import { deleteCrmTask, setCrmTask, updateCrmTask } from "../features/crmTask/slice";
export default ()=>{
 const [data, setData] = useState({
        taskFrequency: "Daily",
        notificationFrequency: {
            times: [Date.now()],
            date: Date.now(),
            day: "",
        },
    })
    const [loading, setLoading] = useState(false);
    const {crmTask} = useSelector(state=>state.crmTask)
    const [deleteLoading, setDeleteLoading] = useState(false);
    const navigator = useNavigate()
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const dispatch = useDispatch()
    const {showAlert} = useAlert()
    const [filter, setfilter] = useState({
      company: ''
    })
    const getTasks = async () => {
      setLoading("initial");
      try {
        const response = await api.get(`/crm-task/getAll`, { params: { ...filter, page, limit } });
        if (response.data.status) {
          // setCrmTasks(response.data.result);
          dispatch(setCrmTask(response.data.result))
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    const changeStatus = async (id,status) => {
      setLoading("initial");
      try {
        console.log(id,status)
        const response = await api.put(`/crm-task/update-status`, { _id:id,status });
        console.log(response)
        if (response.data.status) {
          // setCrmTasks(response.data.result);
          dispatch(updateCrmTask(response.data.result))
          showAlert('Status updated.','success')
        }else{
          showAlert(response.data.message,'danger')
        }
      } catch (error) {
        showAlert(error.message,'danger')
      } finally {
        setLoading(false);
      }
    };
    const deleteTask = async (id) => {
        if (!(await DeleteAlert())) return;
        setDeleteLoading(id);
      try {
        const response = await api.delete(`/crm-task/delete?_id=${id}`);
        console.log(response)
        if (response.data.status) {
          dispatch(deleteCrmTask(response.data.result))
          showAlert('Task Deleted.','success')
        }else{
          showAlert(response.data.message,'danger')
        }
      } catch (error) {
        showAlert(error.message,'danger')
      } finally {
        setDeleteLoading(null);
      }
    };
    const addTask = async () => {
        setLoading("new")
        try {
            const response = await api.post(`/crm-task/add`, data)
            if(response.data.status){
                showAlert('Task added.','success')
                navigator('/crm-to-do')
            }else{
                showAlert(response.data.message,'danger')
            }
        } catch (error) {
            showAlert(error.message,'danger')
        } finally {
            setLoading(false)
        }
    }
    const updateTask = async () => {
        setLoading("new")
        try {
            const response = await api.put(`/crm-task/update`, data)
            console.log(response)
            if(response.data.status){
                showAlert('Task updated.','success')
                navigator('/crm-to-do')
            }else{
                showAlert(response.data.message,'danger')
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    const setTimes = (times) => {
        const arr = times
            ?.map(item => {
                const date = new Date(item);
                const dateTime = new Date(2000, 0, 1);
                dateTime.setHours(date.getHours(), date.getMinutes(), 0, 0);
                return dateTime.getTime()
            })

        setData({
            ...data,
            notificationFrequency: {
                ...data.notificationFrequency,
                times: arr
            }
        })
    }
    return {
        setTimes,addTask,data, setData,updateTask,
        loading, setLoading,
        crmTask,
        deleteLoading, setDeleteLoading,
        page, setPage,
        limit, setLimit,
        filter, setfilter,
        getTasks,
        changeStatus,
        deleteTask
    }
}