import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Screen from "../../components/Screen";
import { AgreementContext } from "../../hooks/agreementContext";
import useAgreement from "../../hooks/useAgreement";
import FilesList from "./fileList";
import useAuth from "../../hooks/useAuth";

export default function Default() {
  const [query] = useSearchParams();
  const template = query.get("template");
  const { isAdmin, isCompany, user, isManager } = useAuth();
  const {
    getAgreements,
    filter,
    setFilter,
    loading,
    getTemplates,
    page,
    setPage,
    setLimit,
    limit,
  } = useAgreement();
  const { removeAllFromShare } = useContext(AgreementContext);
  useEffect(() => {
    removeAllFromShare();
    if (template) {
      getTemplates();
    } else {
      getAgreements(page);
    }
    return () => {};
  }, [filter, template]);
  useEffect(() => {
    if (template) {
      getTemplates();
    } else {
      getAgreements(page);
    }
  }, [page, limit]);

  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setFilter}
      flow={[
        { label: "Agreements", to: "/agreement" },
        { label: "Home", to: "/" },
        { label: "Agreements", to: "/agreement" },
      ]}
      allowed={
        template &&
        user?.subscriptionID?.allowedPOTemplates != undefined &&
        !user?.subscriptionID?.allowedPOTemplates &&
        !isAdmin
      }
    >
      <div className="col-12">
        <FilesList
          template={template}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </div>
    </Screen>
  );
}
