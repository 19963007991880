import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FormField from "../../components/FormField";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import { keys } from "../../config/keys";
import { ChecklistContext } from "../../hooks/checklistContext";
import useAlert from "../../hooks/useAlert";

import {
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import Loader from "../../components/Loader";
import useAgreement from "../../hooks/useAgreement";
import useAuth from "../../hooks/useAuth";
import useCompanies from "../../hooks/useCompanies";
import useEmails from "../../hooks/useEmails";
import useShare from "../../hooks/useShare";
import EmailView from "../share/emailView";
import GroupView from "../share/groupView";
export default function Default() {
  const {
    loading,
    handleAssistentRequest,
    handleEditorChange,
    aiLoading,
    newAgreement,
    data,
    setData,
    aiRequest,
    setAiRequest,
    getAgreement,
    updateAgreement,
    signatureType,
    setSignatureType,
  } = useAgreement();
  const { getEmails, getEmailGroups } = useEmails();
    const {
      loading: shareLoading,
      selectedEmails,
      manualEmail,
      manualName,
      handleRemoveEmail,
      handleAddEmail,
      handleAddMultipleEmails,
      addGroupField,
      addNameField,
    } = useShare()
  const { showAlert } = useAlert();
  const { isCompany } = useAuth();
  const navigate = useNavigate();
  const { fetchCompanies } = useCompanies();
  const { categories } = useContext(ChecklistContext);
  const { isAdmin } = useAuth();
  const location = useLocation();
  const [query] = useSearchParams();
  const agreementID = query.get("id");
  const assistantField = {
    value: aiRequest,
    setValue: (value) => {
      setAiRequest(value);
    },
    placeholder: "Search Anything Here...",
    type: "text",
    id: "aiRequest",
  };
  const titleField = {
    value: data?.title,
    setValue: (value) => {
      setData({ ...data, title: value });
    },
    placeholder: "Enter Agreement title",
    type: "text",
    id: "title",
  };
  const categoryField = {
    type: "dropdown",
    options: categories,
    placeholder: "Select Agreement Category",
    value: data?.categoryID,
    setValue: (value) => {
      setData({
        ...data,
        categoryID: value == "Select Agreement category" ? "" : value,
      });
    },
    id: "categoryID",
  };
  const signatureTypeField = {
    type: "dropdown",
    options: ["File", "Text"],
    placeholder: "Select Signature Type",
    value: signatureType,
    setValue: (value) => {
      setSignatureType(value);
    },
    id: "signatureType",
  };
  // const companyField = {
  //   type: "dropdown",
  //   options: companies,
  //   placeholder: "Select Company",
  //   value: data?.companyID,
  //   setValue: (value) => {
  //     setData({ ...data, companyID: value == "Select Company" ? "" : value });
  //   },
  //   id: "companyID",
  // };
  const signatureField = {
    type: "file",
    placeholder: "Select checklist category",
    value: data?.signature,
    setValue: async (value) => {
      try {
        const formData = new FormData();
        formData.append("image", value);
        await fetch(`${keys.BASE_URL}/file/upload`, {
          method: "POSt",
          body: formData,
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.status) {
              setData({ ...data, signature: response.result });
              showAlert(response.message, "success");
            } else {
              showAlert(response.message, "danger");
            }
          });
      } catch (error) {
        showAlert("unexpected error! Try again later.", "danger");
      }
    },
    id: "signature",
  };
  const signatureTextField = {
    value: data?.signature,
    setValue: (value) => {
      setData({ ...data, signature: value });
    },
    placeholder: "Enter Your Name",
    type: "text",
    id: "signature",
  };
  const modules = {
    toolbar: [[{ header: [1, 2, false] }], ["bold"]],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];
  const autofillList = [
    "[[CompanyEmail]]",
    "[[CompanyName]]",
    "[[CompanyRole]]",
    "[[CompanyNature]]",
    "[[CompanyNoOfEmployees]]",
    "[[CompanyWebsite]]",
    "[[CompanyTelephone1]]",
    "[[CompanyNoOfBranches]]",
    "[[CompanyAddress]]",
    "[[CompanyWorkStartTime]]",
    "[[CompanyWorkEndTime]]",
    "[[AdminName]]",
    "[[AdminEmail]]",
  ];
  useEffect(() => {
    if (isAdmin) fetchCompanies();
    getEmailGroups();
  }, []);
  useEffect(() => {
    if (agreementID || location.state?._id) {
      getAgreement(agreementID || location.state?._id);
    }
  }, [agreementID]);

  return (
    <Screen
      loading={loading}
      flow={[
        { label: "Agreement", to: "/agreementInput" },
        { label: "Home", to: "/" },
        { label: "Agreement", to: "/agreementInput" },
      ]}
    >
      <div className="d-flex justify-content-end mb-3 gap-3">
        <>
          <SubmitButton
            title={agreementID ? "Update Agreement" : "Save Agreement"}
            loading={loading}
            onClick={() => {
              if (agreementID) {
                updateAgreement(agreementID);
              } else {
                newAgreement(selectedEmails);
              }
            }}
            color={"success"}
            loaderColor={"white"}
            disabled={
              data.title == "" ||
              data.categoryID == "" ||
              data.description == "" ||
              data.signature == ""
            }
          />
        </>
      </div>
      {/* Title */}
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "flex",
            lg: "flex",
          },
          justifyContent: "space-between",
        }}
      >
        {/** Agreement */}
        <div className="col-md-6 col-12">
          <div className="card">
            <div className="card-body">
              {/* <Box> */}
              <div className="col-md-12 col-12">
                <h5 className="card-title">Add Agreement Title</h5>
                <FormField item={titleField} />
              </div>
              <div className="col-md-12 col-12">
                <h5 className="card-title">Category</h5>
                <FormField item={categoryField} />
              </div>
              {/* {isAdmin && (
                <div className="col-md-12 col-12">
                  <h5 className="card-title">Company</h5>
                  <FormField item={companyField} />
                </div>
              )} */}
              <h5 className="card-title">Description</h5>
              <Box
                sx={{
                  height: "580px",
                }}
              >
                <ReactQuill
                  style={{
                    height: "500px",
                  }}
                  value={data.description}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                  theme="snow"
                />
              </Box>
              <div className="col-md-12 col-12">
                <h5 className="card-title">Signature Type</h5>
                <FormField item={signatureTypeField} />
              </div>
              {signatureType != "" && (
                <div className="col-md-12 col-12">
                  <h5 className="card-title">Signature</h5>
                  <FormField
                    item={
                      signatureType == "File"
                        ? signatureField
                        : signatureTextField
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/** AI Assistant */}
        <div className="col-md-5 col-12">
          <div className="card">
            <div className="card-body">
              <div className="col-md-12 col-12">
                <h5 className="card-title">Ask AI Assistant</h5>
                <div className="d-flex">
                  <FormField item={assistantField} />
                  <SubmitButton
                    style={{ marginLeft: "15px" }}
                    title={"Search"}
                    loading={loading}
                    onClick={() => {
                      handleAssistentRequest();
                    }}
                    color={"success"}
                    loaderColor={"white"}
                    disabled={false}
                  />
                </div>
                <Box
                  sx={{
                    height: "250px",
                    border: "1px solid #00000012",
                    marginTop: "20px",
                    overflow: "auto",
                    padding: "10px",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  {aiLoading && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        height: "100%",
                        width: "100%",
                        zIndex: "999999999999",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                        }}
                      >
                        <Loader loading={aiLoading} center></Loader>
                      </Box>
                    </Box>
                  )}
                  {data.aiResponse}{" "}
                  {(isCompany && data.aiResponse) ==
                    "Disclaimer: Only use AI for inspiration. For accurate & up-to-date advice, " && (
                      <label
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                        onClick={() =>
                          navigate(
                            `/profile?companyID=${localStorage.getItem("_id")}`
                            // { state: { tab: "#profile-support" } }
                          )
                        }
                      >
                        Contact us here...
                      </label>
                    )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginTop: "15px",
                  }}
                  className="d-flex justify-between"
                >
                  <SubmitButton
                    title={"Add To Description"}
                    loading={loading}
                    onClick={() => {
                      setData({ ...data, description: data.aiResponse });
                    }}
                    color={"success"}
                    loaderColor={"white"}
                    disabled={false}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
        </Box>
      {/** Emails */}
      <div className="col-md-12 col-12">
        <div className="card">
          <div className="card-header">
            Emails
          </div>
          <div className="card-body">
            <div className="col-md-12 col-lg-12 pt-2">
              <div
                style={{
                  height: "300px",
                  overflow: "auto",
                }}
                className="card"
              >
                <div className="card-body">
                  <div className="col-md-12 col-12">
                    <h5 className="card-title">Selected Emails</h5>
                    <div className="d-flex gap-2">
                      <FormField item={addGroupField} />
                      <FormField item={addNameField} />
                      <SubmitButton
                        style={{ marginLeft: "15px" }}
                        title={"Add"}
                        loading={shareLoading}
                        onClick={() => handleAddEmail({ email: manualEmail, name: manualName })}
                        color={"success"}
                        loaderColor={"white"}
                        disabled={manualEmail == ""}
                      />
                    </div>
                    {selectedEmails?.length > 0 && (
                      <Box
                        sx={{
                          maxHeight: "150px",
                          overflow: "auto",
                          marginTop: "15px",
                          border: "1px solid #00000012",
                          padding: "5px",
                        }}
                      >
                        {selectedEmails?.map((item) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              backgroundColor: "#00000012",
                              padding: "3px 6px",
                              margin: "5px 0px",
                              borderRadius: "8px",
                            }}
                          >
                            <Typography>{item.email}</Typography>
                            <Typography
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => handleRemoveEmail(item)}
                            >
                              x
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <GroupView getEmails={getEmails} />
            <EmailView
              handleAddEmail={handleAddEmail}
              selectedEmails={selectedEmails}
              handleAddMultipleEmails={handleAddMultipleEmails}
            />
          </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-12">
        <div className="card">
          <div className="card-body">
            <div className="col-md-12 col-12">
              <h5 className="card-title">Autofill List</h5>
              <Grid container>
                {autofillList?.map((item) => (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <li>{item}</li>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </Screen>
  );
}
