import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "../../components/Loader";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import Table from "../../components/Table";
import { capitalFirst, capitalFirstLetter, convertToLocal, ddmmyyyy } from "../../config/functions";
import useAuth from "../../hooks/useAuth";
import useCrmTask from "../../hooks/useCrmTask";
import { useNavigate } from "react-router-dom";
import Model from "../../components/model"
let listStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  padding: '6px 0px'
}
export default function Default() {
  const {
    loading,
    crmTask,
    deleteLoading,
    page, setPage,
    limit, setLimit,
    filter, setfilter,
    getTasks,
    changeStatus,
    deleteTask
  } = useCrmTask()
  useEffect(() => {
    getTasks();
  }, [filter, page, limit]);
  const { isAdmin } = useAuth();
   const navigator = useNavigate()
  const labels = [
    "#",
    "Subject",
    "Priority",
    "Status",
    "Due Date",
    "Company",
    "Created At",
    isAdmin && "Actions",
  ].filter((item) => item);
  const [show, setShow] = useState(null)
  const data = useMemo(() => {
    if (!crmTask || crmTask.length === 0) return [];
    return crmTask.map((item,index) => {
      return {
        data: item,
        fields: [
          index + 1,
          item?.subject,
          item?.priority,
          <select
            className="form-select"
            onChange={(e) => changeStatus(item._id, e.target.value)}
            style={{ width: '140px', marginRight: '10px' }}
            aria-label="Default select example"
          >
            <option
              value={'pending'}
              selected={item.status == 'pending'}
            >
              Pending
            </option>
            <option
              value={'completed'}
              selected={item.status == 'completed'}
            >
              Completed
            </option>

          </select>,
          item?.dueDate?.substring(0, 10),
          <td>{item.companyID?.name}</td>,
          convertToLocal(item?.createdAt),
          isAdmin && (
            <div className="d-flex gap-3">
               {
                <i
                  className="bi bi-eye text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShow(item)
                  }}
                ></i>
              }
              {
                <i
                  className="bi bi-pencil-square text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator(`/add-crm-to-do?edit=true`, {
                      state: { ...item },
                    });
                  }}
                ></i>
              }
              {item?._id == deleteLoading ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={deleteLoading != null} color={"red"} />
                </div>
              ) : (
                <i
                  className="bi bi-trash text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteTask(item._id)}
                ></i>
              )}
            </div>
          ),
        ].filter((it) => it),
      };
    });
  }, [crmTask, deleteLoading]);
  return (
    <Screen
      loading={loading == "initial"}
      filter={filter}
      setFilter={setfilter}
      flow={[
        { label: `CRM Tasks`, to: `/crm-to-do` },
        { label: "Home", to: "/" },
        { label: `CRM Tasks`, to: `/crm-to-do` },
      ]}
    >
       {show && <Model title="Task Details" show={show} handleClose={() => setShow(null)}>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Subject</Typography>
          <Typography>{show?.subject}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Company</Typography>
          <Typography>{show?.companyID?.name || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Status</Typography>
          <Typography>{show?.status}</Typography>
          
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Priority</Typography>
          <Typography>{capitalFirstLetter(show?.priority) || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Due Date</Typography>
          <Typography>{convertToLocal(show?.dueDate) || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Frequency</Typography>
          <Typography>{show?.taskFrequency || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Date added</Typography>
          <Typography>{convertToLocal(show?.createdAt) || 'None'}</Typography>
        </Box>
      </Model>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: "20px",
        }}
      >
        <SubmitButton
          title={"Add Task"}
          onClick={() => {
            navigator(`/add-crm-to-do`);
          }}
          color={"primary"}
        />
        <select
          className="form-select"
          onChange={(e) => setfilter({ ...filter, status: e.target.value })}
          style={{ width: '140px', marginRight: '10px' }}
          aria-label="Default select example"
        >
          <option value="">Select Status</option>
          <option
            value={'pending'}
            selected={filter.status == 'pending'}
          >
            Pending
          </option>
          <option
            value={'completed'}
            selected={filter.status == 'completed'}
          >
            Completed
          </option>

        </select>
        <select
          className="form-select"
          onChange={(e) => setfilter({ ...filter, priority: e.target.value })}
          style={{ width: '140px', marginRight: '10px' }}
          aria-label="Default select example"
        >
          <option value="">Select Priority</option>
          <option
            value={'low'}
            selected={filter.priority == 'low'}
          >
            Low
          </option>
          <option
            value={'medium'}
            selected={filter.priority == 'medium'}
          >
            Medium
          </option>
          <option
            value={'high'}
            selected={filter.status == 'high'}
          >
            High
          </option>

        </select>
      </Box>
      <Table
        labels={labels}
        data={data}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      // totalData={totalMsDocs}
      />
    </Screen>
  );
}
