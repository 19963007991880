import { createSlice } from "@reduxjs/toolkit";

const documentSlice = createSlice({
  name: "agreement",
  initialState: {
    agreements: [],
  },
  reducers: {
    setAgreement: (state, action) => {
      state.agreements = action.payload;
    },
    updateAgreement: (state, action) => {
      state.agreements = state.agreements.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteAgreementt: (state, action) => {
      state.agreements = state.agreements.filter(
        (item) => item._id != action.payload._id
      );
    },
    addAgreement: (state, action) => {
      state.agreements = [...state.agreements, action.payload];
    }
  },
});

export const {
  setAgreement,
  updateAgreement,
  deleteAgreementt,
  addAgreement,
} = documentSlice.actions;

export default documentSlice.reducer;
