import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import Table from '../../components/Table';
import { convertToLocal } from '../../config/functions';
import useAccount from '../../hooks/useAccount';
import useAuth from '../../hooks/useAuth';
import Model from "../../components/model"
let listStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  padding: '6px 0px'
}
export default function Default() {
  const {
    getAccounts,
    deleteAccountt,
    limit, setLimit,
    page, setPage,
    deleteLoading,
    accounts,
    loading,
    labels,
    filter, setfilter,
    getCrmAttachments,
    comments,
    popupLoading
  } = useAccount()
  const navigator = useNavigate()
  useEffect(() => {
    getAccounts()
  }, [filter,page,limit])
  const { isAdmin } = useAuth();
  const [show, setShow] = useState(null)
  const data = useMemo(() => {
    if (!accounts || accounts.length === 0) return [];
    return accounts.map((item, index) => {
      return {
        data: item,
        fields: [
          index + 1,
          item?.name || "-",
          <td>{item.linkedAccount?.name}</td>,
          <td>{item?.linkedAccount?.email || item?.email}</td>,
          <td>{item?.linkedAccount?.nature?.name || item?.nature?.name}</td>,
          <td>{item?.addedBy?.name}</td>,
          convertToLocal(item?.createdAt),
          isAdmin && (
            <div className="d-flex gap-3">
              {
                <i
                  className="bi bi-eye text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    getCrmAttachments(item._id)
                    setShow(item)
                  }}
                ></i>
              }
              {
                <i
                  className="bi bi-pencil-square text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator(`/add-crm-account?edit=true`, {
                      state: { ...item }
                    })
                  }}
                ></i>
              }
              {item?._id == deleteLoading ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={deleteLoading != null} color={"red"} />
                </div>
              ) : (
                <i
                  className="bi bi-trash text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteAccountt(item._id)}
                ></i>
              )}
            </div>
          ),
        ].filter((it) => it),
      };
    });
  }, [accounts, deleteLoading]);
  return (
    <Screen
      loading={loading == 'initial'}
      filter={filter}
      setFilter={setfilter}
      flow={[
        { label: `Accounts`, to: `/crm-collection` },
        { label: "Home", to: "/" },
        { label: `Accounts`, to: `/crm-collection` },
      ]}>
      <Model title="Account Details" show={show} handleClose={() => setShow(null)} loading={popupLoading}>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Name</Typography>
          <Typography>{show?.name}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
          <Typography>{show?.email || show?.linkedAccount?.email}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Linked Account</Typography>
          <Typography>{show?.linkedAccount?.name || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Subscription</Typography>
          <Typography>{show?.linkedAccount?.subscriptionID?.name || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Subscription validity</Typography>
          <Typography>{show?.linkedAccount?.subscriptionID?.validity || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Subscription validity till</Typography>
          <Typography>{convertToLocal(show?.linkedAccount?.endDate) || 'None'}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Nature</Typography>
          <Typography>{show?.nature?.name}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Address</Typography>
          <Typography>{show?.address}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Phone</Typography>
          <Typography>{show?.phone}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Website</Typography>
          <Typography>{show?.website}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>No of employees</Typography>
          <Typography>{show?.employees}</Typography>
        </Box>
        <Box sx={listStyle}>
          <Typography sx={{ fontWeight: 'bold' }}>Date added</Typography>
          <Typography>{convertToLocal(show?.createdAt)}</Typography>
        </Box>
        <div className='mt-3'>

        {comments?.map((item, index) => (
          <div key={index} className="col-md-12 col-12">
            <div className="card pt-4">
              <div className="card-body">
                <Box sx={{ display: 'flex', alignItems: 'center' }}><h6 style={{ padding: '0', fontWeight: 'bold' }}>Date Added: </h6><h6 className="mx-2">{item.createdAt?.substring(0, 10)}</h6></Box>
                <Box sx={{ display: 'flex' }}><h6 style={{ padding: '0', paddingRight: '10px', fontWeight: 'bold' }} >{`Comment: `}</h6><h6 className="">{item.text}</h6></Box>
                <Link to="/">View attachments</Link>
              </div>
            </div>
          </div>
        ))}
        </div>
      </Model>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        marginBottom: '20px'
      }}>
        <SubmitButton
          title={"Add Account"}
          onClick={() => {
            navigator(`/add-crm-account`)
          }}
          color={"primary"}

        />
      </Box>
      <Table
        labels={labels}
        data={data}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      // totalData={totalMsDocs}
      />
    </Screen>
  )
}
