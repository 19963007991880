import { useAutoAnimate } from "@formkit/auto-animate/react";
import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import FormField1 from "../../components/FormField";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import { frequency } from "../../config/constants";
import { ddmmyyyy, hhmm } from "../../config/functions";
import useCompanies from "../../hooks/useCompanies";
import useCrmTask from "../../hooks/useCrmTask";
import { FormField } from "../dynamicSubscription/index";
export default function Default() {
  const { setTimes, addTask,updateTask, data, setData, loading } = useCrmTask();
  const location = useLocation();
  let editData = location.state;
  const [query] = useSearchParams();
  const edit = query.get("edit");
  const { fetchCompanies, companies } = useCompanies();
  useEffect(() => {
    if (editData) {
      setData({
        ...editData,
        companyID: editData?.companyID?._id || editData.companyID,
      });
    }
    fetchCompanies();
  }, [editData]);
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [animationParent] = useAutoAnimate();
  const times = data?.notificationFrequency?.times;
  const notificationFrequencyFields = times?.map((time, index) => ({
    value: hhmm(time),
    setValue: (value) => {
      const date = new Date();
      date.setHours(parseInt(value.split(":")[0]));
      date.setMinutes(parseInt(value.split(":")[1]));
      const newTime = date.getTime();
      const newArray = times?.map((item, i) => (i == index ? newTime : item));
      setTimes(newArray);
    },
    type: "time",
    id: `taskTime${index}`,
    placeholder: "Select frequency time",
  }));
  let fields = [
    {
      value: data?.subject,
      setValue: (value) => setData({ ...data, subject: value }),
      label: "Enter subject",
      placeholder: "Enter subject",
      type: "text",
      id: "subject",
      required: true,
    },
    {
      value: data?.companyID,
      setValue: (value) => setData({ ...data, companyID: value }),
      label: "Select company",
      placeholder: "Select company",
      options: companies,
      type: "dropdown",
      id: "companyID",
    },
    {
      value: data?.status,
      setValue: (value) => setData({ ...data, status: value }),
      label: "Select status",
      placeholder: "Select status",
      options: [
        {
          name: "Pending",
          value: "pending",
        },
        {
          name: "Completed",
          value: "completed",
        },
      ],
      type: "dropdown",
      id: "status",
    },
    {
      value: data?.priority,
      setValue: (value) => setData({ ...data, priority: value }),
      label: "Select priority",
      placeholder: "Select priority",
      options: [
        {
          name: "Low",
          value: "low",
        },
        {
          name: "Medium",
          value: "medium",
        },
        {
          name: "High",
          value: "high",
        },
      ],
      type: "dropdown",
      id: "priority",
    },
    {
      value: editData ? data.dueDate?.substring(0, 10) : data?.dueDate,
      setValue: (value) => setData({ ...data, dueDate: value }),
      label: "Select due date",
      placeholder: "Select due date",
      type: "date",
      id: "dueDate",
    },
    {
      value: data?.taskFrequency,
      setValue: (value) => setData({ ...data, taskFrequency: value }),
      label: "Select frequency",
      type: "radio",
      options: [...frequency, "None"],
      id: "taskFrequency",
    },
    data?.taskFrequency != "Weekly"
      ? null
      : {
          placeholder: "Select Day",
          value: data?.notificationFrequency?.day,
          type: "dropdown",
          options: days,
          setValue: (value) => {
            setData({
              ...data,
              notificationFrequency: {
                ...data.notificationFrequency,
                day: value,
              },
            });
          },
          id: "day",
        },
    data?.taskFrequency != "Monthly"
      ? null
      : {
          placeholder: "Select Day",
          value: data?.notificationFrequency?.day,
          type: "dropdown",
          options: [...new Array(31)].map((item, index) => `${index + 1}`),
          setValue: (value) => {
            setData({
              ...data,
              notificationFrequency: {
                ...data.notificationFrequency,
                day: value,
              },
            });
          },
          id: "day",
        },
    data?.taskFrequency != "Annual"
      ? null
      : {
          value: ddmmyyyy(data?.notificationFrequency?.date),
          setValue: (value) => {
            const date = new Date(value);
            setData({
              ...data,
              notificationFrequency: {
                ...data.notificationFrequency,
                date: date.getTime(),
              },
            });
          },
          type: "date",
          id: "checklistTitle",
          placeholder: "Select frequency date",
        },
  ].filter((it) => it);
  return (
    <Screen
      loading={loading == "initial"}
      flow={[
        { label: `${editData ? 'Update':'Add'} Task`, to: `/add-crm-task` },
        { label: "Home", to: "/" },
        { label: `${editData ? 'Update':'Add'} Task`, to: `/add-crm-task` },
      ]}
    >
      <div className="row gap-3 card py-4">
        <div className="card-body">
          {fields.map((item) => (
            <div className="col-12">
              <FormField field={item} />
            </div>
          ))}
          {data?.taskFrequency == "None" ? null : (
            <div className="col-md-12 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="card-title">Set Notification Times</h5>
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <ul ref={animationParent} className="ps-0">
                      {notificationFrequencyFields?.map((item, index) => (
                        <li
                          className="d-flex gap-3 mb-3 align-items-center"
                          key={index}
                        >
                          <FormField1 item={item} />
                          {index == 0 ? null : (
                            <i
                              onClick={() =>
                                setTimes(times?.filter((it, i) => index != i))
                              }
                              className="bi bi-trash3 text-danger"
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                    <span
                      className="text-primary text-center"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setTimes([
                          ...times,
                          Date.now() + times?.length * 3600000,
                        ])
                      }
                    >
                      <i className="bi bi-plus-circle"></i> Add More
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mt-4">
          <SubmitButton
            title={edit ? "Update" : "Add task"}
            loading={loading == "new" || loading == "update"}
            onClick={() => {
              
              if (editData?._id) {
                updateTask()
              } else {
                addTask();
              }
            }}
            color={"success"}
            loaderColor={"white"}
            disabled={data.name == ""}
          />
        </div>
      </div>
    </Screen>
  );
}
