import { createSlice } from "@reduxjs/toolkit";

const accountSlice = createSlice({
    name: "accounts",
    initialState: {
        accounts: [],
        comments: []
    },
    reducers: {
        setAccount: (state, action) => {
            state.accounts = action.payload;
        },
        updateAccount: (state, action) => {
            state.accounts = state.accounts.map(item => item._id == action.payload._id ? action.payload : item);
        },
        deleteAccount: (state, action) => {
            state.accounts = state.accounts.filter(item => item._id != action.payload._id);
        },
        addAccount: (state, action) => {
            state.accounts = [...state.accounts, action.payload];
        },
        addComment: (state, action) => {
            state.comments = [...state.comments, action.payload];
        },
        setComment: (state, action) => {
            state.comments = action.payload;
        },
    },
});

export const { setAccount, updateAccount, deleteAccount, addAccount,addComment,setComment } = accountSlice.actions;

export default accountSlice.reducer;
