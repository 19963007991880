import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../app/api";
import Loader from "../../components/Loader";
import Screen from "../../components/Screen";
import { DeleteAlert } from "../../components/Swal Alert";
import Table from "../../components/Table";
import { convertToLocal } from "../../config/functions";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { Switch } from "@mui/material";
import { deleteCoupons, setCoupons, updateCoupons } from "./slice";

const FilesList = () => {
    const { coupons } = useSelector(state => state.coupons)
    const [deleteLoading, setDeleteLoading] = useState(null);
    const [updateLoading, setUpdateLoading] = useState(null)
    const [resendLoading, setResendLoading] = useState(null)
    const dispatch = useDispatch()
    const [loading, setloading] = useState(false);
    const [filter, setfilter] = useState({
        company: "",
        admin: "",
    });
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const { showAlert } = useAlert()
    const getData = async () => {
        setloading("initial");
        try {
            let response = await api.get(`/promocode/getAll`, {params:{
                ...filter,
                page,
                limit,
            }});
            if (response.data?.status) {
                dispatch(setCoupons(response.data?.result));
            }
        } catch (error) {
        } finally {
            setloading(null);
        }
    };
    const removeCoupon = async (id) => {
        if (!(await DeleteAlert())) return;
        setDeleteLoading(id)
        try {
            let response = await api.delete(`/promocode/delete?_id=${id}`);
            if (response.data?.status) {
                showAlert(response?.data?.message, "success")
                dispatch(deleteCoupons(response.data?.result));
            } else {
                showAlert(response?.data?.message, "danger")
            }
        } catch (error) {

        } finally {
            setDeleteLoading(null)
        }
    }
    const handleStatusChange = async (id, status) => {
        setUpdateLoading(id)
        try {
            let response = await api.put(`/promocode/updateStatus?_id=${id}&status=${status}`);
            if (response.data?.status) {
                showAlert(response?.data?.message, "success")
                dispatch(updateCoupons(response.data?.result));
            } else {
                showAlert(response?.data?.message, "danger")
            }
        } catch (error) {

        }finally{
            setUpdateLoading(null)
        }
    }
    const { isAdmin } = useAuth();

    const labels = [
        "#",
        "Name",
        "Promocode",
        "Date Added",
        "Type",
        "Added By",
        "Status",
        isAdmin && "Actions",
    ].filter((item) => item);
    const data = useMemo(() => {
        if (!coupons || coupons.length === 0) return [];
        return coupons.map((item) => {
            return {
                data: item,
                fields: [
                    "#",
                    item?.name,
                    <td>{item?.promoCode}</td>,
                    convertToLocal(item?.createdAt),
                    <td>{item?.duration}</td>,
                    <td>{item?.addedBy?.name}</td>,
                    <div className="d-flex gap-3">
                        {item?._id == updateLoading ? (
                            <div className="d-flex align-items-center justify-content-center ">
                                <Loader loading={updateLoading != null} color={"red"} />
                            </div>
                        ) : (
                            <Switch
                                checked={item.status}
                                onChange={() => handleStatusChange(item._id, !item.status)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        )}
                    </div>,
                    isAdmin && (
                        <div className="d-flex gap-3">
                            {item?._id == deleteLoading ? (
                                <div className="d-flex align-items-center justify-content-center ">
                                    <Loader loading={deleteLoading != null} color={"red"} />
                                </div>
                            ) : (
                                <i
                                    className="bi bi-trash text-danger"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removeCoupon(item._id)}
                                ></i>
                            )}
                        </div>
                    ),
                ].filter((it) => it),
            };
        });
    }, [coupons, deleteLoading, loading, resendLoading, updateLoading]);
    useEffect(() => {
        getData();
    }, [page, limit, filter]);

    return (
        <Screen
            loading={loading == "initial"}

            flow={[
                { label: `Proposals`, to: `/proposal-list` },
                { label: "Home", to: "/checklists" },
                { label: `Proposals`, to: `/proposal-list` },
            ]}
        >
            <Table
                labels={labels}
                data={data}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
            // totalData={totalMsDocs}
            />
        </Screen>
    );
};

export default FilesList;
