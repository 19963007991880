import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../app/api";
import { DeleteAlert } from "../components/Swal Alert";
import { deleteContact, setContacts } from "../features/contact/slice";
import useAlert from "./useAlert";
export default () => {
    const [data, setData] = useState({})
    const [filter, setfilter] = useState({
        company: ''
    })
    const [loading, setLoading] = useState(false);
    const { contacts } = useSelector(state => state.contacts)
    const dispatch = useDispatch()
    // const [contacts, setContacts] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { showAlert } = useAlert()
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
     const navigator = useNavigate();
    const getContacts = async () => {
        setLoading("initial");
        try {
            const response = await api.get(`/contact/getAll`, { params: { page, limit, ...filter } });
            console.log(response.data)
            if (response.data.status) {
                dispatch(setContacts(response.data.result))
            } else {
                showAlert('unable to fetch contacts', 'danger')
            }
        } catch (error) {
            showAlert(error.message, 'danger')
        } finally {
            setLoading(false);
        }
    };
    const deleteContactt = async (id) => {
        if (!(await DeleteAlert())) return;
        setDeleteLoading(id);
        try {
            const response = await api.delete(`/contact/delete?_id=${id}`);
            if (response.data.status) {
                dispatch(deleteContact(response.data.result))
                showAlert('contact deleted.', 'success')
            } else {
                showAlert(response.data.message, 'danger')
            }
        } catch (error) {
            showAlert(error.message, 'danger')
        } finally {
            setDeleteLoading(null);
        }
    };
    const addContact = async () => {
        setLoading("new")
        try {
            const response = await api.post(`/contact/add`, data)
            if (response.data.status) {
                showAlert('contact added.', 'success')
                navigator('/contacts')
            } else {
                showAlert(response.data.message, 'danger')
            }
        } catch (error) {
            showAlert(error.message, 'danger')
        } finally {
            setLoading(false)
        }
    }
    const updateContact = async () => {
        setLoading("new")
        try {
            const response = await api.put(`/contact/update`, data)
            console.log(response)
            if (response.data.status) {
                showAlert('contact updated.', 'success')
                navigator('/contacts')
            } else {
                showAlert(response.data.message, 'danger')
            }
        } catch (error) {
            showAlert(error.message, 'danger')
        } finally {
            setLoading(false)
        }
    }
    return {
        filter, setfilter,
        data, setData,
        addContact,
        loading, setLoading,
        contacts,
        deleteLoading, setDeleteLoading,
        page, setPage,
        limit, setLimit,
        getContacts,
        deleteContactt,
        updateContact
    }
}