import { Checkbox } from "@mui/material";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../app/api";
import DownloadButton from "../../components/downloadButton";
import EmptyData from "../../components/EmptyData";
import FormField from "../../components/FormField";
import Loader from "../../components/Loader";
import Screen from "../../components/Screen";
import { DeleteAlert } from "../../components/Swal Alert";
import Table from "../../components/Table";
import { convertToLocal } from "../../config/functions";
import { PoliciesContext } from "../../hooks/policiesContext";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import PDF from "./pdf";
const FilesList = () => {
  //   const { policies } = useSelector((state) => state.Policy);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [submissionData, setSubmissionData] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(null)
  const { isCompany, isAdmin, user, isManager } = useAuth();
  const [limit, setlimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setfilter] = useState({ company: "", branch: "" });
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setloading] = useState(false);
  const { showAlert } = useAlert()
  const status = queryParams.get("status");
  const {
    selectedForShare,
    addForShare,
    removeFromShare,
    removeAllFromShare,
    addAllToShare,
  } = useContext(PoliciesContext);
  const getData = async () => {
    setloading(true);
    try {
      const response = await api.get(
        `/shareSubmissions/get?${isCompany
          ? `id=${localStorage.getItem("_id")}`
          : isManager
            ? `id=${user.branchID}`
            : `companyID=${filter.company}`
        }&page=${page}&limit=${limit}&branch=${filter.branch}&status=${status == 'pending' ? status : ''}`,
        {
          ...(isCompany && { id: localStorage.getItem("_id") }),
          ...(isAdmin && { ...filter }),
        }
      );
      if (response.data.status) {
        setTotalCount(response.data.total)
        setSubmissionData(response.data.result);
      }
    } catch (err) {
      showAlert(err.message, "danger");
    } finally {
      setloading(false);
    }
  };
  const removeAll = async (item) => {
    if (!(await DeleteAlert())) return;
    setloading(true);
    try {
      // let ids = [];
      let ids = selectedForShare.map(item => item?._id)
      console.log(ids)
      await api.post(
        `/shareSubmissions/deleteBulk`, { ids }
      ).then(response => {
        if (response.data.status) {
          setPage(1)
          getData()
          removeAllFromShare()
          showAlert("Deleted successfully", "success");
        } else {
          showAlert(response.data.message, "danger");
          setloading(false)
        }
      });

    } catch (err) {

      setloading(false);
      //(message);
      showAlert(err.message, "danger");
    } finally {
      // setloading(false)
    }
  };
  const removeOne = async (id) => {
    if (!(await DeleteAlert())) return;
    setDeleteLoading(id);
    console.log(deleteLoading)
    try {
      await api.delete(
        `/shareSubmissions/deleteSingle`, { params: { id } }
      ).then(response => {
        if (response.data.status) {
          getData()
          showAlert("Deleted successfully", "success");
        } else {
          showAlert(response.data.message, "danger");
          setloading(false)
        }
      });

    } catch (err) {

      setloading(false);
      //(message);
      showAlert(err.message, "danger");
    } finally {
      // setloading(false)
    }
  };
  const labels = [
    (isAdmin || isCompany || isManager) ? (
      <Checkbox
        checked={selectedForShare.length == submissionData.length}
        onChange={async (e) => {
          if (e.target.checked) {
            addAllToShare(submissionData ? submissionData : []);
          } else {
            removeAllFromShare();
          }
        }}
      />
    ) : (
      "#"
    ),
    "Submitted By",
    "Type",
    "Submitted At",
    status != 'pending' && "Confirmation",
    "Content",
    "Action"
  ].filter((item) => item);
  const [localFilter, setLocalFilter] = useState({
    name: "",
    email: ""
  })
  const data = useMemo(() => (
    submissionData
      ? submissionData.map((item) => {
        if (localFilter.name !== "" || localFilter.email !== "") {
          if ((localFilter.name !== "" ? item.submittedName?.includes(localFilter.name?.toLowerCase()) : true)
            && (localFilter.email !== "" ? item.submittedEmail?.includes(localFilter.email?.toLowerCase()) : true)) {
            return {
              data: item,
              fields: [
                (isAdmin || isCompany || isManager) ? (
                  <Checkbox
                    checked={selectedForShare.includes(item)}
                    onChange={async (e) => {
                      if (e.target.checked) {
                        addForShare(item);
                      } else {
                        removeFromShare(item);
                      }
                    }}
                  />
                ) : (
                  item._id?.substring(0, 5)
                ),
                item?.submittedName ? item.submittedName : item.submittedEmail,
                item?.forFeature,
                convertToLocal(item?.createdAt),
                status != 'pending' ? <DownloadButton
                  PDF={<PDF data={item} />}
                  item={item}
                  fileName={`email-confirmation-` + item?.submittedEmail + `.pdf`}
                /> : null,
                <a
                  href={`/shareView?id=${item.shareableID?._id
                    }&isUser=${true}&userID=${localStorage.getItem("_id")}`}
                  target="_blank"
                >
                  View
                </a>,
                (isAdmin || isCompany || isManager) && (
                  <div className="d-flex gap-3">
                    {deleteLoading?._id == item?._id ? (
                      <div className="d-flex align-items-center justify-content-center ">
                        <Loader loading={loading} color={"red"} />
                      </div>
                    ) : (
                      <i
                        className="bi bi-trash text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeOne(item._id)}
                      ></i>
                    )}
                  </div>)
              ].filter((it) => it),
            };
          } else {
            return null
          }
        } else {
          return {
            data: item,
            fields: [
              (isAdmin || isCompany || isManager) ? (
                <Checkbox
                  checked={selectedForShare.includes(item)}
                  onChange={async (e) => {
                    if (e.target.checked) {
                      addForShare(item);
                    } else {
                      removeFromShare(item);
                    }
                  }}
                />
              ) : (
                item._id?.substring(0, 5)
              ),
              item?.submittedName ? item.submittedName : item.submittedEmail,
              item?.forFeature,
              convertToLocal(item?.createdAt),
              status != 'pending' ? <DownloadButton
                PDF={<PDF data={item} />}
                item={item}
                fileName={`email-confirmation-` + item?.submittedEmail + `.pdf`}
              /> : null,
              <a
                href={`/shareView?id=${item.shareableID?._id
                  }&isUser=${true}&userID=${localStorage.getItem("_id")}`}
                target="_blank"
              >
                View
              </a>,
              (isAdmin || isCompany || isManager) && (
                <div className="d-flex gap-3">
                  {deleteLoading?._id == item?._id ? (
                    <div className="d-flex align-items-center justify-content-center ">
                      <Loader loading={loading} color={"red"} />
                    </div>
                  ) : (
                    <i
                      className="bi bi-trash text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => removeOne(item._id)}
                    ></i>
                  )}
                </div>)
            ].filter((it) => it),
          };
        }
      }).filter(item => item) : []
  ), [localFilter, submissionData, selectedForShare, deleteLoading]);
  useEffect(() => {
    getData();
  }, [filter, page, limit]);
  useEffect(() => {
    setfilter({ company: "", branch: "" })
    setPage(1)
    getData();
  }, [status]);

  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setfilter}
      showBranchFilter={isCompany || isAdmin}
      flow={status == 'pending' ? [
        { label: "Sent Emails", to: "/sentMails?status=pending" },
        { label: "Home", to: "/" },
        { label: "Sent Emails", to: "/sentMails?status=pending" },
      ] : [
        { label: "Email Confirmations", to: "/sentMails" },
        { label: "Home", to: "/" },
        { label: "Email Confirmations", to: "/sentMails" },
      ]}
    >
      <div className="row mb-4">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <FormField item={{
            type: "text",
            value: localFilter.email,
            placeholder: "Email...",
            setValue: (val) => setLocalFilter({ ...localFilter, "email": val })
          }} />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <FormField item={{
            type: "text",
            value: localFilter.name,
            placeholder: "Name...",
            setValue: (val) => setLocalFilter({ ...localFilter, "name": val })
          }} />
        </div>
      </div>
      <EmptyData data={data}>
        <Table
          labels={labels}
          data={data}
          limit={limit}
          setLimit={setlimit}
          page={page}
          setPage={setPage}
          removeAllDocument={removeAll}
          totalData={totalCount}
        />
      </EmptyData>
    </Screen>
  );
};

export default memo(FilesList);
