import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import useCompanies from '../../hooks/useCompanies';
import useContact from '../../hooks/useContact';
import { FormField } from "../dynamicSubscription/index";
export default function Default() {
    const {
        data, setData,
        addContact,
        loading,
        updateContact
    } = useContact()
    const location = useLocation()
    let editData = location.state
    const [query] = useSearchParams()
    const edit = query.get('edit')
    const {
        fetchCompanies,
        companies
    } = useCompanies();

    useEffect(() => {
        if (editData) {
            setData({
                ...editData,
                companyID: editData?.companyID?._id,
            })
        }
        fetchCompanies()
    }, [editData])
    let fields = [
        {
            value: data?.name,
            setValue: (value) => setData({ ...data, name: value }),
            label: "Enter account name",
            placeholder: "Enter account name",
            type: "text",
            id: "name",
            required: true,
        },
        {
            value: data?.companyID,
            setValue: (value) => setData({ ...data, companyID: value }),
            label: "Select company",
            placeholder: "Select company",
            required: true,
            disabled: edit ? true : false,
            options: companies,
            type: "dropdown",
            id: "companyID",
        },
        {
            value: data?.address,
            setValue: (value) => setData({ ...data, address: value }),
            label: "Enter address",
            placeholder: "Enter address",
            type: "text",
            id: "address",
        },
        {
            value: data?.phone,
            setValue: (value) => setData({ ...data, phone: value }),
            label: "Enter telephone",
            placeholder: "Enter telephone",
            type: "text",
            id: "phone",
        },
        {
            value: data?.mobile,
            setValue: (value) => setData({ ...data, mobile: value }),
            label: "Enter mobile",
            placeholder: "Enter mobile",
            type: "text",
            id: "mobile",
        },
        {
            value: data?.email,
            setValue: (value) => setData({ ...data, email: value }),
            label: "Enter email",
            placeholder: "Enter email",
            type: "email",
            disabled: !edit && data.linkedAccount,
            id: "email",
        },
        {
            value: data?.description,
            setValue: (value) => setData({ ...data, description: value }),
            label: "Enter description",
            placeholder: "Enter description",
            type: "text",
            id: "description",
        },
    ].filter(it => it);
    return (
        <Screen
            loading={loading == 'initial'}
            flow={[
                { label: `${editData ? 'Update' : 'Add'} Contact`, to: `/add-crm-account` },
                { label: "Home", to: "/" },
                { label: `${editData ? 'Update' : 'Add'} Contact`, to: `/add-crm-account` },
            ]}>
            <div className="row gap-3 card py-4">
                <div className="card-body">
                    {fields.map((item) => (
                        <div className="col-12">
                            <FormField field={item} />
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <SubmitButton
                        title={edit ? "Update" : "Add account"}
                        loading={loading == "new" || loading == "update"}
                        onClick={() => {
                            if (editData?._id) {
                                updateContact()
                            } else {
                                addContact()
                            }
                        }}
                        color={"success"}
                        loaderColor={"white"}
                        disabled={
                            data.name == ""
                        }
                    />
                </div>
            </div>
        </Screen>
    )
}
