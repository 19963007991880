import React, { useState } from 'react'
import Screen from '../../components/Screen'
import { Box } from "@mui/material";
import ReactQuill from "react-quill";
import SubmitButton from '../../components/SubmitButton';
import useAlert from '../../hooks/useAlert';
import api from '../../app/api';
export default function Default() {
    const [loading, setloading] = useState(false)
    const [data, setData] = useState({
        type: "",
        type: "",
        duration: "",
        duration_in_months: "",
        promoCode: "",
        amount_off: "",
        percent_off: ""
    })
    const { showAlert } = useAlert()
    const onAddCoupon = async () => {
        setloading("new")
        try {
            console.log(data)
            const response = await api.post(
                `/promocode/add`,
                { ...data,addedBy:localStorage.getItem('_id') }
            );
            console.log(response)
            if (response.data.status) {
                showAlert(response.data.message, "success")
                // navigate("/list-Proposal")
            } else {
                showAlert(response.data.message, "danger")
            }
        } catch (error) {
            showAlert(error.message, "danger")
        } finally {
            setloading(null)
        }
    }
    let fields = [
        {
            value: data?.name,
            setValue: (value) => setData({ ...data, name: value }),
            label: "Name",
            type: "text",
            id: "name",
            required: true,
        },
        {
            value: data?.type,
            setValue: (value) => setData({ ...data, type: value }),
            label: "Select type",
            required: true,
            options: [
                {
                    name: "Amount Off",
                    value: "amount_off",
                },
                {
                    name: "Percent Off",
                    value: "percent_off",
                },
            ],
            type: "dropdown",
            id: "type",
        },
        ...(data.type == 'amount_off' ? [{
            value: data?.amount_off,
            setValue: (value) => setData({ ...data, amount_off: value }),
            label: "Enter amount off",
            type: "text",
            id: "amount_off",
            required: true,
        }] : []),
        ...(data.type == 'percent_off' ? [{
            value: data?.percent_off,
            setValue: (value) => setData({ ...data, percent_off: value }),
            label: "Enter percent off",
            type: "text",
            id: "percent_off",
            required: true,
        }] : []),
        {
            value: data?.duration,
            setValue: (value) => setData({ ...data, duration: value }),
            label: "Select duration",
            placeholder: "Select duration",
            required: true,
            options: [
                {
                    name: "Once",
                    value: "once",
                },
                {
                    name: "Repeating",
                    value: "repeating",
                },
                {
                    name: "Forever",
                    value: "forever",
                },
            ],
            type: "dropdown",
            id: "duration",
        },
        ...(data?.duration != 'forever' ? [{
            value: data?.validTill,
            setValue: (value) => {
                // Create a Date object
                const dateObject = new Date(value);

                // Extract the components
                const year = dateObject.getFullYear(); // Get the year
                const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Get the month (0-based, so add 1)
                const day = String(dateObject.getDate()).padStart(2, '0'); // Get the day

                // Format as YYYY-MM-DD
                const formattedDate = `${year}-${month}-${day}`;
                setData({ ...data, validTill: formattedDate })
            },
            label: "Select expiry date",
            type: "date",
            id: "validTill",
            required: true,
        }] : []),
        ...(data?.duration == 'repeating' ? [{
            value: data?.duration_in_months,
            setValue: (value) => setData({ ...data, duration_in_months: value }),
            label: "Enter validity in months",
            type: "number",
            id: "duration_in_months",
            required: true,
        },] : []),
        {
            value: data?.promoCode,
            setValue: (value) => setData({ ...data, promoCode: value.toUpperCase() }),
            label: "Enter promocode",
            type: "text",
            id: "promoCode",
            required: true,
        }
    ].filter(it => it);
    return (
        <Screen
            loading={loading == "initial"}

            flow={[
                { label: `Add Promo Code`, to: `/add-Promocode` },
                { label: "Home", to: "/checklists" },
                { label: `Add Promo Code`, to: `/add-Promocode` },
            ]}
        >
            <div className="card gap-3 py-4">

                <div className="card-body">
                    {fields.map((item) => (
                        <div className="col-12">
                            <FormField field={item} />
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center mt-4 gap-2">

                    <SubmitButton
                        title={"Add Promocode"}
                        loading={loading == "new"}
                        onClick={() => {
                            onAddCoupon();
                        }}
                        color={"success"}
                        loaderColor={"white"}
                        disabled={
                            data.name == "" ||
                            data.type == "" ||
                            data.duration == "" ||
                            (data.duration_in_months == "" && data.type == "forever")||
                            data.promoCode == "" ||
                            (data.amount_off == "" && data.percent_off == "")
                        }
                    />
                </div>
            </div>
        </Screen>
    )
}
const FormField = ({ field }) => {
    const onChange = (e) => field.setValue(e.target.value);
    const modules = {
        // toolbar: [[{ header: [1, 2, false] }], ["bold"]],
        toolbar: [
            [{ header: [1, 2, false] }], // Dropdown for header formatting
            ["bold", "italic", "underline", "strike"], // Text styles
            [{ list: "ordered" }, { list: "bullet" }], // Lists
            ["link"], // Media insertion
        ],
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "bullet",
        "link",
        "image",
    ];
    return (
        <div className="row mb-3">
            <label htmlFor={field.id} className="col-md-4 col-lg-3 col-form-label">
                {field.label}
                {field.required ? <span className="text-danger"> *</span> : null}
            </label>
            <div className="col-md-8 col-lg-9">
                {field.type == "longtext" ? (
                    <textarea
                        name={field.id}
                        className="form-control"
                        id={field.id}
                        style={{ height: 100 }}
                        onChange={onChange}
                        value={field.value}
                    />
                )
                    : field.type == "dropdown" ? (
                        <>
                            <select
                                id={field.id}
                                value={field.value}
                                className="form-select"
                                onChange={onChange}
                            >
                                <option
                                    selected={
                                        field?.value == "" ||
                                        field.value == null ||
                                        field.value == undefined
                                    }
                                    value={""}
                                >
                                    {`-- ${field.label} --`}
                                </option>
                                {field?.options?.map((val, index) => {
                                    const value = !val.value ? val._id : val.value;
                                    const display = !!val.name ? val.name : val;
                                    return (
                                        <option
                                            key={index}
                                            selected={field?.value == value}
                                            value={value}
                                        >
                                            {display}
                                        </option>
                                    );
                                })}
                            </select>
                        </>
                    )
                        : field.type == "styleText" ? (
                            <>
                                <Box
                                    sx={{
                                        height: "580px",
                                    }}
                                >
                                    <ReactQuill
                                        style={{
                                            height: "500px",
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                        modules={modules}
                                        formats={formats}
                                        theme="snow"
                                    />
                                </Box>
                            </>
                        )
                            : (
                                <input
                                    name={field.id}
                                    type={field.type}
                                    className="form-control"
                                    id={field.id}
                                    value={field.value}
                                    onChange={onChange}
                                    placeholder={field.label}
                                />
                            )}
            </div>
        </div>
    );
};