import { createSlice } from "@reduxjs/toolkit";

const couponSlice = createSlice({
  name: "coupons",
  initialState: {
    coupons: [],
  },
  reducers: {
    setCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    updateCoupons: (state, action) => {
      state.coupons = state.coupons.map((item) =>
        item._id == action.payload._id ? action.payload : item
      );
    },
    deleteCoupons: (state, action) => {
      state.coupons = state.coupons.filter(
        (item) => item._id != action.payload._id
      );
    },
    addCoupons: (state, action) => {
      state.coupons = [...state.coupons, action.payload];
    }
  },
});

export const {
  setCoupons,
  updateCoupons,
  deleteCoupons,
  addCoupons,
} = couponSlice.actions;

export default couponSlice.reducer;
