import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import EmptyData from "../../components/EmptyData";
import FormField from "../../components/FormField";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import useEmails from "../../hooks/useEmails";
import useShare from "../../hooks/useShare";
import EmailView from "./emailView";
import FilesList from "./fileList";
import GroupView from "./groupView";
export default function Default() {
  const {
    loading,
    selectedEmails,
    selectedForShare,
    manualEmail,
    manualName,
    handleRemoveEmail,
    handleAddEmail,
    handleAddMultipleEmails,
    handleShare,
    addGroupField,
    addNameField
  } = useShare()
  const { getEmails, getEmailGroups } = useEmails();

  useEffect(() => {
    getEmailGroups();
  }, []);

  return (
    <Screen
      loading={loading}
      flow={[
        { label: "Share", to: "/share" },
        { label: "Home", to: "/" },
        { label: "Share", to: "/share" },
      ]}
    >
      <EmptyData data={selectedForShare}>
        <div className="d-flex justify-content-end mb-3 gap-3">
          {
            <>
              <SubmitButton
                title={"Send Mail"}
                loading={loading == "new"}
                onClick={handleShare}
                color={"outline-primary"}
                loaderColor={"white"}
                disabled={selectedEmails.length < 1}
              />
            </>
          }
        </div>
        <div>
          <div className="row">
            <FilesList />
            <div className="col-md-12 col-lg-6">
              <div
                style={{
                  height: "300px",
                  overflow: "auto",
                }}
                className="card"
              >
                <div className="card-body">
                  <div className="col-md-12 col-12">
                    <h5 className="card-title">Selected Emails</h5>
                    <div className="d-flex gap-2">
                      <FormField item={addGroupField} />
                      <FormField item={addNameField} />
                      <SubmitButton
                        style={{ marginLeft: "15px" }}
                        title={"Add"}
                        loading={loading}
                        onClick={() => handleAddEmail({ email: manualEmail, name: manualName })}
                        color={"success"}
                        loaderColor={"white"}
                        disabled={manualEmail == ""}
                      />
                    </div>
                    {selectedEmails?.length > 0 && (
                      <Box
                        sx={{
                          maxHeight: "150px",
                          overflow: "auto",
                          marginTop: "15px",
                          border: "1px solid #00000012",
                          padding: "5px",
                        }}
                      >
                        {selectedEmails?.map((item) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              backgroundColor: "#00000012",
                              padding: "3px 6px",
                              margin: "5px 0px",
                              borderRadius: "8px",
                            }}
                          >
                            <Typography>{item.email}</Typography>
                            <Typography
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => handleRemoveEmail(item)}
                            >
                              x
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <GroupView getEmails={getEmails} />
            <EmailView
              handleAddEmail={handleAddEmail}
              selectedEmails={selectedEmails}
              handleAddMultipleEmails={handleAddMultipleEmails}
            />
          </div>
        </div>
      </EmptyData>
    </Screen>
  );
}
