import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../app/api";
import { PoliciesContext } from "./policiesContext";
import useAlert from "./useAlert";
import useEmails from "./useEmails";
export default () => {
    const [loading, setloading] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const { selectedForShare, removeAllFromShare } = useContext(PoliciesContext);
    const location = useLocation();
    const navigator = useNavigate();
    const [manualEmail, setManualEmail] = useState("");
    const [manualName, setManualName] = useState("");
    const { showAlert } = useAlert();
    const { isValidEmail } = useEmails();
    const handleRemoveEmail = (item) => {
        setSelectedEmails((prevState) => {
            let newState = [...prevState];
            let updatedImportedEmails = newState.filter((itemm) => itemm != item);
            return updatedImportedEmails;
        });
    };
    const handleAddEmail = (item) => {
        if (!isValidEmail(item.email)) {
            showAlert("Invalid Email!", "danger");
            return;
        }
        console.log(selectedEmails.filter(itemm => itemm.email == item.email))
        if (selectedEmails.filter(itemm => itemm.email == item.email)?.length > 0) {
            // showAlert("Email Already Added", "danger");
            setManualEmail("");
            setManualName("");
            return;
        }
        setSelectedEmails((prevState) => {
            let newState = [...prevState];
            newState.push({ email: item.email, name: item.name ? item.name : '' });
            return newState;
        });
        setManualEmail("");
        setManualName("");
    };
    const handleAddMultipleEmails = (emails) => {
        if (emails.length > 0) {
            emails.map((item) => {
                if (!isValidEmail(item.email)) {
                    showAlert("Invalid Email!", "danger");
                    return;
                }
                if (selectedEmails.includes(item)) {
                    showAlert("Email Already Added", "danger");
                    return;
                }
                setSelectedEmails((prevState) => {
                    let newState = [...prevState];
                    newState.push({ email: item.email, name: item.name ? item.name : '' });
                    return newState;
                });
                setManualEmail("");
                setManualName("");
            })
        } else {
            setSelectedEmails([]);
        }
    };
    const handleShare = async () => {
        setloading("new");
        try {
            const response = await api.post("/share/add", {
                allowedEmails: selectedEmails,
                shareable: selectedForShare,
                forFeature: location.state?.isDocument ? "document" : location.state?.isManagementSystem ? "management system" : location.state?.isForm ? "form" : "policy",
                createdBy: localStorage.getItem("_id"),
            });
            if (response.data?.status == true) {
                navigator("/dashboard");
                showAlert("Email Sent Successfully!", "success");
                setSelectedEmails([]);
                removeAllFromShare();
                setManualEmail("");
                setManualName("")
            } else {
                showAlert(response.data?.message, "danger");
            }
        } catch (error) {
            showAlert(error?.message, "danger");
        } finally {
            setloading(null);
        }
    };
    const addGroupField = {
        value: manualEmail,
        setValue: (value) => {
            setManualEmail(value);
        },
        placeholder: "Enter Email...",
        type: "text",
        id: "manualEmail",
    };
    const addNameField = {
        value: manualName,
        setValue: (value) => {
            setManualName(value);
        },
        placeholder: "Enter Name...",
        type: "text",
        id: "manualName",
    };
    return {
        loading, setloading,
        selectedEmails, setSelectedEmails,
        selectedForShare, removeAllFromShare,
        manualEmail, setManualEmail,
        manualName, setManualName,
        showAlert,
        handleRemoveEmail,
        handleAddEmail,
        handleAddMultipleEmails,
        handleShare,
        addGroupField,
        addNameField
    }
}