import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import useAccount from '../../hooks/useAccount';
import useNature from '../../hooks/useNature';
import { FormField } from "../dynamicSubscription/index";
export default function Default() {
    const {
        getAllUsers,
        addAccount,
        addAttachment,
        getCrmAttachments,
        comments,
        users,
        data, setData,
        selectedUser, setSelectedUser,
        commentData, setCommentData,
        loading,
        updateAccount
    } = useAccount()
    const location = useLocation()
    let editData = location.state
    const [query] = useSearchParams()
    const edit = query.get('edit')
    const {
        fetchNatures,
        natures
    } = useNature();

    useEffect(() => {
        if (editData) {
            console.log(editData)
            setData({
                ...editData,
                linkedAccount: editData?.linkedAccount?._id,
                nature: editData?.linkedAccount?.nature._id ? editData.linkedAccount?.nature?._id : editData.nature?._id,
                email: editData.email ? editData.email : editData.linkedAccount?.email
            })
            setSelectedUser(editData.linkedAccount)
            getCrmAttachments(editData._id)
        }
        getAllUsers()
        fetchNatures()
    }, [editData])
    let fields = [
        {
            value: data?.name,
            setValue: (value) => setData({ ...data, name: value }),
            label: "Enter account name",
            placeholder: "Enter account name",
            type: "text",
            id: "name",
            required: true,
        },
        {
            value: data?.linkedAccount,
            setValue: (value) => {
                setData({ ...data, linkedAccount: value })
                let selectedAccount = users.filter(item => item._id == value)
                setSelectedUser(selectedAccount[0])
                setData(prevState => {
                    let newState = { ...prevState }
                    newState.linkedAccount = value
                    newState.address = selectedAccount[0]?.address ? selectedAccount[0].address : ''
                    newState.phone = selectedAccount[0]?.phone ? selectedAccount[0].phone : ''
                    newState.website = selectedAccount[0]?.website ? selectedAccount[0].website : ''
                    newState.email = selectedAccount[0]?.email ? selectedAccount[0].email : ''
                    newState.nature = selectedAccount[0]?.nature ? selectedAccount[0].nature._id : ''
                    newState.employees = selectedAccount[0]?.employees ? selectedAccount[0].employees : ''
                    return newState
                })
            },
            label: "Select linked account",
            placeholder: "Select linked account",
            required: true,
            // disabled: edit ? true : false,
            options: users,
            type: "dropdown",
            id: "linkedAccount",
        },
        {
            value: data?.address,
            setValue: (value) => setData({ ...data, address: value }),
            label: "Enter address",
            placeholder: "Enter address",
            disabled: !edit && selectedUser?.role == 'company',
            type: "text",
            id: "address",
            required: true,
        },
        {
            value: data?.phone,
            setValue: (value) => setData({ ...data, phone: value }),
            label: "Enter phone",
            placeholder: "Enter phone",
            disabled: !edit && selectedUser?.role == 'company',
            type: "text",
            id: "phone",
            required: true,
        },
        {
            value: data?.website,
            setValue: (value) => setData({ ...data, website: value }),
            label: "Enter website",
            placeholder: "Enter website",
            disabled: !edit && selectedUser?.role == 'company',
            type: "text",
            id: "website",
            required: true,
        },
        ...(selectedUser?.role != 'company' ? [
            {
                value: data?.nature,
                setValue: (value) => setData({ ...data, nature: value }),
                label: "Select industry",
                placeholder: "Select industry",
                required: true,
                // disabled: edit ? true : false,
                options: natures,
                type: "dropdown",
                id: "nature",
            }
        ] : []),
        {
            value: data?.email,
            setValue: (value) => setData({ ...data, email: value }),
            label: "Enter email",
            placeholder: "Enter email",
            type: "email",
            disabled: data.linkedAccount,
            id: "email",
            required: true,
        },
        {
            value: data?.employees,
            setValue: (value) => setData({ ...data, employees: value }),
            label: "Enter employees",
            placeholder: "Enter employees",
            disabled: !edit && selectedUser?.role == 'company',
            type: "number",
            id: "employees",
            required: true,
        },
    ].filter(it => it);
    return (
        <Screen
            loading={loading == 'initial'}
            flow={[
                { label: `${editData ? 'Update' : 'Add'} Account`, to: `/add-crm-account` },
                { label: "Home", to: "/" },
                { label: `${editData ? 'Update' : 'Add'} Account`, to: `/add-crm-account` },
            ]}>
            <div className="row gap-3 card py-4">
                <div className="card-body">
                    {fields.map((item) => (
                        <div className="col-12">
                            <FormField field={item} />
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <SubmitButton
                        title={edit ? "Update" : "Add account"}
                        loading={loading == "new" || loading == "update"}
                        onClick={() => {
                            if (editData?._id) {
                                updateAccount()
                            } else {
                                addAccount()

                                // onAddSubscription();
                            }
                        }}
                        color={"success"}
                        loaderColor={"white"}
                        disabled={
                            data.name == ""
                        }
                    />
                </div>
                {edit && <>
                    <div className='mt-3'>
                        <FormField
                            field={{
                                value: commentData?.text,
                                setValue: (value) => setCommentData({ ...commentData, text: value }),
                                label: "Enter any comment",
                                placeholder: "Enter phone",
                                type: "longtext",
                                id: "text",
                            }}
                        />
                        <div className="row mb-3">
                            <label htmlFor={"attachments"} className="col-md-4 col-lg-3 col-form-label">
                                Attachments
                            </label>
                            <div className="col-md-8 col-lg-9">

                                <input
                                    type="file"
                                    id="attachments"
                                    multiple
                                    className="form-control"
                                    onChange={(e) => {
                                        const selectedFiles = Array.from(e.target.files);
                                        setCommentData({
                                            ...commentData,
                                            attachments: selectedFiles,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <SubmitButton
                                title={"Add Comments"}
                                loading={loading == "attachment"}
                                onClick={() => {
                                    addAttachment(editData)
                                }}
                                color={"success"}
                                loaderColor={"white"}
                                disabled={
                                    commentData?.text == "" && commentData?.attachments?.length < 1
                                }
                            />
                        </div>
                    </div>
                    <div className='mt-3'>
                        {comments?.map((item, index) => (
                            <div key={index} className="col-md-12 col-12">
                                <div className="card pt-4">
                                    <div className="card-body">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}><h6 style={{ padding: '0', fontWeight: 'bold' }}>Date Added: </h6><h6 className="mx-2">{item.createdAt?.substring(0, 10)}</h6></Box>
                                        <Box sx={{ display: 'flex' }}><h6 style={{ padding: '0', paddingRight: '10px', fontWeight: 'bold' }} >{`Comment: `}</h6><h6 className="">{item.text}</h6></Box>
                                        <Link to="/">View attachments</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>}
            </div>
        </Screen>
    )
}
