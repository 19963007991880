import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../app/api';
import { DeleteAlert } from '../components/Swal Alert';
import { deleteAccount, setAccount, addComment, setComment } from '../features/crmAccount/slice';
import useAlert from './useAlert';
import useAuth from './useAuth';
export default () => {
    const [loading, setLoading] = useState(false)
    const { accounts } = useSelector(state => state.crmAccounts)
    const { isAdmin } = useAuth();
    const dispatch = useDispatch()
    const { showAlert } = useAlert()
    const [filter, setfilter] = useState({
        company: ''
    })
     const [popupLoading, setPopupLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const navigator = useNavigate()
    const labels = [
        "#",
        "Name",
        "Linked Account",
        "Email",
        "Nature",
        "Added By",
        "Created At",
        isAdmin && "Actions",
    ].filter((item) => item);
    const getAccounts = async () => {
        setLoading("initial")
        try {
            const response = await api.get(`/crm-account/getAll`, { params: { page, limit, ...filter } })
            console.log(response)
            if (response.data.status) {
                dispatch(setAccount(response.data.result))
            }
        } catch (error) {
            showAlert(error.message, "danger")
        } finally {
            setLoading(false)
        }
    }
    const deleteAccountt = async (id) => {
        if (!(await DeleteAlert())) return;
        setDeleteLoading(id)
        try {
            const response = await api.delete(`/crm-account/delete?_id=${id}`)
            if (response.data.status) {
                showAlert("account deleted.", "success")
                dispatch(deleteAccount(response.data.result))
            } else {
                showAlert(response.data.message, "danger")
            }
        } catch (error) {
            showAlert(error.message, "danger")
        } finally {
            setDeleteLoading(null)
        }
    }
    const { comments } = useSelector(state => state.crmAccounts)
    const [users, setUsers] = useState([])
    const [data, setData] = useState({})
    const [selectedUser, setSelectedUser] = useState(null)
    const [commentData, setCommentData] = useState({
        text: "",
        attachments: []
    })
    const getAllUsers = async () => {
        try {
            const response = await api.get(`/crm-account/getAllUsers`)
            setUsers(response.data.result)
        } catch (error) {
            showAlert(error.message, "danger")
        }
    }
    const addAccount = async () => {
        setLoading("new")
        try {
            const response = await api.post(`/crm-account/add`, data)
            if (response.data.status) {
                showAlert("Account added successfully.", "success")
                navigator("/crm-collection")
            } else {
                showAlert(response.data.message, "danger")
            }
        } catch (error) {
            showAlert(error.message, "danger")
        } finally {
            setLoading(false)
        }
    }
    const updateAccount = async () => {
        setLoading("new")
        try {
            console.log(data)
            const response = await api.put(`/crm-account/update`, data)
            if (response.data.status) {
                showAlert("Account updated successfully.", "success")
                navigator("/crm-collection")
            } else {
                showAlert(response.data.message, "danger")
            }
        } catch (error) {
            showAlert(error.message, "danger")
        } finally {
            setLoading(false)
        }
    }
    const addAttachment = async (editData) => {
        setLoading("attachment")
        try {
            const formData = new FormData()
            formData.append('text', commentData.text)
            formData.append('account', editData._id)
            if (commentData.attachments && Array.isArray(commentData.attachments)) {
                commentData.attachments.forEach((file) => {
                    formData.append('attachments', file); // Use the same key for multiple files
                });
            }
            const response = await api.post(`/crm-attachment/add`, formData)
            if (response.data.status) {
                dispatch(addComment(response.data.result))
                document.getElementById('attachments').value = ''
                setCommentData({
                    text: "",
                    attachments: []
                })
                showAlert("Comment added successfully.", "success")
            } else {
                showAlert(response.data.message, "danger")
            }
        } catch (error) {
            showAlert(error.message, "danger")
        } finally {
            setLoading(false)
        }
    }
    const getCrmAttachments = async (account) => {
        setPopupLoading(true)
        try {
            const response = await api.get(`/crm-attachment/getAll?account=${account}`)
            if (response.data.status) {
                dispatch(setComment(response.data.result))
            } else {
                dispatch(setComment([]))
            }
        } catch (error) {
            showAlert(error.message, "danger")
        }finally{
            setPopupLoading(false)
        }
    }
    return {
        getAccounts,
        deleteAccountt,
        limit, setLimit,
        page, setPage,
        deleteLoading, setDeleteLoading,
        accounts,
        loading, setLoading,
        labels,

        getAllUsers,
        addAccount,
        addAttachment,
        getCrmAttachments,
        comments,
        users, setUsers,
        data, setData,
        selectedUser, setSelectedUser,
        commentData, setCommentData,
        updateAccount,
        filter, setfilter,
        popupLoading, setPopupLoading
    }
}