import React from 'react';
import Modal from "react-bootstrap/Modal";


import Loader from './Loader';
export default function Model({title, children, show, handleClose,loading}) {
    return (
        <Modal show={show} centered onHide={handleClose}>
            <Modal.Header>
                <div className='row w-100'>
                    <Modal.Title 
                    // className={isCompany ? 'col-8' : 'col-12'}
                    >{title}</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
            <Loader loading={loading} center>
                {children}
            </Loader>
            </Modal.Body>
        </Modal>
    )
}
