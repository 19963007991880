import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import api from "../../app/api";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import TaskAnswer from "../../components/taskAnswer";
import useAlert from "../../hooks/useAlert";
import Loader from "../../components/Loader";
import BreadCrumb from "../../components/BreadCrumb";
const Task = () => {
    const location = useLocation();
    const { showAlert } = useAlert();
    const [isSubmitted, setisSubmitted] = useState(false)
    const [loading, setLoading] = useState(null);
    const [questions, setQuestions] = useState([])
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const isUser = queryParams.get("isUser");
    const encodedEmail = queryParams.get("email");
    const userID = queryParams.get("userID");
    const email = customDecode(encodedEmail);
    const [shareable, setShareable] = useState({});
    function customDecode(encodedEmail) {
        if (!isUser) {
            return encodedEmail
                ?.split("-")
                ?.map((code) => String.fromCharCode(code - 5))
                .join("");
        }
    }
    const getData = async () => {
        try {
            const response = await api.get(`/share/get?id=${id}&isUser=${isUser}`);
            if (response.data?.status) {
                setShareable(response.data.result);

                if (response.data?.result?.allowedEmails?.filter(item => item.email == email).length < 1 && !isUser) {
                    showAlert("Restricted page!", "danger");
                } else {
                    if (
                        userID === localStorage.getItem("_id") ||
                        response.data?.result?.allowedEmails?.filter(item => item.email == email).length > 0
                    ) {
                        setQuestions(response.data?.result?.data[0]?.questions)
                    } else showAlert("Restricted page!", "danger");
                }
            } else {
                showAlert(response.data?.message, "danger");
            }
        } catch (error) {
        } finally {
            setLoading(false)
        }
    };
    const performTask = async () => {
        const error = questions
            ?.filter(
                (item) =>
                    (item.required && item.answer == undefined) || item.answer == "" || item.answer == '--Select an option--'
            )
            .map((item) => `${item.question} is required`);
        if (error?.length != 0) {
            return showAlert(error.join("\n"), "danger");
        }
        setLoading(true);
        try {
            var formData = new FormData();
            questions.forEach((item, index) => {
                const fieldname = `questions[${index}]`;
                formData.append(fieldname + "[_id]", item._id);
                formData.append(
                    fieldname + "[answer]",
                    item.answer ? item.answer : "Not Answered"
                );
                formData.append(
                    fieldname + "[answerAttachments]",
                    item.answerAttachments ? item.answerAttachments : []
                );
                formData.append(
                    fieldname + "[comment]",
                    item.comment ? item.comment : ''
                );
            });
            const response = await api.put(`/shareSubmissions/updateForm`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
                params: {
                    submittedEmail: email,
                    shareableID: shareable._id,
                },
            });
            if (response.data?.status) {
                showAlert("Confirmation Submitted!", "success");
                setisSubmitted(true);
                email = "";
            } else {
                showAlert(response.data?.message, "danger");
            }
        } catch (error) {
        } finally {
            setLoading();
        }
    };
    const getAnswer = (id) => {
        const question = questions?.find((item) => item._id == id);
        return question.answer;
    };
    const getComment = (id) => {
        const question = questions?.find((item) => item._id == id);
        return question.comment;
    };
    const setAnswer = (id, answer, checkbox) => {
        if (!checkbox) {
            const updated = questions?.map((item) =>
                item._id == id ? { ...item, answer } : item
            );
            setQuestions(updated);
        } else {
            const question = questions.filter((item) => id == item._id);
            if (question[0].answer?.includes(answer)) {
                const updatedAnswer = question[0].answer?.filter(
                    (item) => item != answer
                );
                const updated = questions?.map((item) =>
                    item._id == id ? { ...item, answer: updatedAnswer } : item
                );
                setQuestions(updated);
            } else {
                const updated = questions?.map((item) =>
                    item._id == id
                        ? {
                            ...item,
                            answer: item.answer ? [...item.answer, answer] : [answer],
                        }
                        : item
                );
                setQuestions(updated);
            }
        }
    };
    const setAttachment = (id, answerAttachments) => {
        const updated = questions?.map((item) =>
            item._id == id ? { ...item, answerAttachments } : item
        );
        setQuestions(updated);
    };
    const removeAtachment = async (id, attachment) => {
        let findQuestion = questions.filter(item => item._id == id)
        let findAttachments = findQuestion[0].answerAttachments
        let updatedAttachments = findAttachments.filter(item => item != attachment)
        findQuestion[0].answerAttachments = updatedAttachments
        await api.delete(`/file/delete?url=${attachment}`);
        const updated = questions?.map((item) =>
            item._id == id ? findQuestion[0] : item
        );
        setQuestions(updated);
    };
    const setComment = (id, comment) => {
        const updated = questions?.map((item) =>
            item._id == id ? { ...item, comment } : item
        );
        setQuestions(updated);
    };
    const disabled = false;
    const fresh = location.state?.fresh;
    const isRiskAssesment = location.state?.isRiskAssesment;
    const isAudit = location.state?.isAudit;

    useEffect(() => {
        getData(location.state?._id, { fresh, isRiskAssesment, isAudit });
    }, []);

    return (
        <div
            style={{
                border: "15px solid #0d6efd50",
                padding: "40px",
                minHeight: "100vh",
            }}
        >
            <BreadCrumb
                flow={[
                    {
                        label: `Form Submission`,
                        to: `/form-submission`,
                    },
                    { label: "Home", to: "/" },
                    {
                        label: `Form Submission`,
                        to: `/form-submission`,
                    },

                ]}
            />

            {questions?.length > 0 ? <div className="d-flex flex-column align-items-end" id="parentTask">
                <div className="row d-flex justify-content-evenly w-100" id="capture">
                    {questions?.map((item, index) => {
                        const isBoolean = item.type == "boolean";
                        return (
                            <TaskAnswer
                                key={index}
                                item={item}
                                isBoolean={isBoolean}
                                getAnswer={getAnswer}
                                setAnswer={setAnswer}
                                disabled={disabled}
                                setComment={setComment}
                                setAttachment={setAttachment}
                                getComment={getComment}
                                removeAtachment={removeAtachment}
                                data={{}}
                            />
                        );
                    })}
                </div>
                <SubmitButton
                    title={
                        "Submit Answers"
                    }
                    color={"primary"}
                    loading={loading}
                    onClick={() => performTask(fresh, isRiskAssesment, isAudit)}
                    loaderColor={"white"}
                    disabled={isSubmitted}
                />
            </div> : <Loader loading={true} center />}
        </div>
    );
};

export default Task;
