import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import api from "../app/api";
import { DeleteAlert, UpdateAlert } from "../components/Swal Alert/index";
import useAlert from "./useAlert";

import {
  deleteMsDocument,
  setMsDocuments,
  setTotalMsDocs,
  updateMsDocument
} from "../features/managementSystem/slice";
import { PoliciesContext } from "./policiesContext";
import useAuth from "./useAuth";

export default () => {
  const dispatch = useDispatch();
  const { documents, stats } = useSelector((state) => state.document);
  const { removeAllFromShare, selectedForShare } = useContext(PoliciesContext)
  const { showAlert } = useAlert();
  const { isAdmin, isCompanyAdmin, isFellowAdmin, user } = useAuth();
  // STATES
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalName, setModalName] = useState("Add document");
  const [addFolderLoading, setAddFolderLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [companyID, setCompanyID] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedNature, setselectedNature] = useState([]);
  const [catFilter, setCatFilter] = useState([]);
  const [natureFilter, setNatureFilter] = useState([]);
  const [docData, setDocData] = useState({
    name: "",
    file: "",
    folder_id: "",
    _id: "",
  });

  //Add Documents
  const AddNewDocument = async () => {
    setAddLoading(true);
    try {
      let response;
      if (docData?.files?.length > 0) {
        await Promise.all(
          docData?.files?.map(async (item, index) => {
            const formdata = new FormData();
            formdata.append("name", item?.name?.split('.').slice(0, -1).join('.'));
            formdata.append("image", item);
            formdata.append("adminID", isCompanyAdmin ? localStorage.getItem('_id') : isFellowAdmin ? user.adminID : null);
            formdata.append("categoryID", JSON.stringify(selectedCategories));
            formdata.append("natureID", JSON.stringify(selectedNature));

            response = await api.post(`/management-system/add`, formdata);
            console.log(response)
            // dispatch(addDocument(response.data));
          })
        );
      } else {
        const formdata = new FormData();
        formdata.append("name", docData?.name);
        formdata.append("image", docData?.file);
        formdata.append("adminID", isCompanyAdmin ? localStorage.getItem('_id') : isFellowAdmin ? user.adminID : null);
        formdata.append("categoryID", JSON.stringify(selectedCategories));
        formdata.append("natureID", JSON.stringify(selectedNature));
        response = await api.post(`/management-system/add`, formdata);
        console.log(response)
      }
      showAlert("Add Successful", "success");
      setAddLoading(false);
      setModalName("");
      setShowAddModal(false);
      // window.location.reload();
      setDocData({
        name: "",
        file: "",
        folder_id: "",
      });
      setSelectedCategories([]);
      setselectedNature([]);
      fetchDocuments();
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setAddLoading(false);
      //(message);
      //("eerr", err);
      showAlert('Unknown error occured', "danger");
      setAddLoading(false);
    }
  };
  // Documents Itself
  const fetchDocuments = async (nature, categories, accurate, filter) => {
    setLoading(true);
    try {
      //(params)
      const response = await api.post(`/management-system/get`, {
        nature,
        categories,
        page,
        limit,
        companyID: isAdmin ? (filter?.company != '' ? filter?.company : null) : localStorage.getItem("_id"),
        accurate,
        adminID: isCompanyAdmin ? localStorage.getItem('_id') : isFellowAdmin ? user.adminID : undefined,
        search: filter ? filter.search : ''
      });
      if (response.data?.status) {
        dispatch(setTotalMsDocs(response.data.total));
        dispatch(setMsDocuments(response.data.result));
      } else {
        dispatch(setMsDocuments([]));
      }
      //(response.data)
      setLoading(false);
    } catch (err) {
      console.log(err)
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      //(message);
      showAlert('Unknown error occured.', "danger");
      setLoading(false);
    }
  };

  const changeDocument = async () => {
    if (!(await UpdateAlert())) return;
    setAddLoading(true);
    const formdata = new FormData();
    formdata.append("name", docData?.name?.split('.')?.slice(0, -1)?.join('.'));
    formdata.append("_id", docData?._id);
    formdata.append("image", docData?.file);
    // formdata.append("company_id", undefined);
    formdata.append("categoryID", JSON.stringify(selectedCategories));
    formdata.append("natureID", JSON.stringify(selectedNature));
    try {
      const response = await api.put(`/management-system/update/`, formdata);
      if (response.data?.result) {
        showAlert("Updated  successfully", "success");
        dispatch(updateMsDocument(response.data?.result));
        setAddLoading(false);
        fetchDocuments()
        setDocData({
          name: "",
          file: "",
          folder_id: "",
          _id: "",
        });
        setModalName("");
        setShowAddModal(false);
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (err) {
      showAlert(err?.message, "danger");
      setAddLoading(false);
    }
  };
  const removeDocument = async (item) => {
    if (!(await DeleteAlert())) return;
    setDeleteLoading(item);
    try {
      const response = await api.delete(
        `/management-system/delete?id=${item?._id}`
      );
      if (response?.data?.status) {
        showAlert("Deleted Document successfully", "success");
        dispatch(deleteMsDocument(item));
      } else {
        showAlert(response.data?.message, "danger");
      }
      setDeleteLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setDeleteLoading(false);
      //(message);
      showAlert(message, "danger");
      setDeleteLoading(false);
    }
  };
  const addForCompany = async (bodyData) => {
    setCompanyID(bodyData.companyID);
    try {

      let idsData = bodyData?.files.filter(f => ({ _id: f._id }))
      const response = await api.post(
        "/management-system/addForCompany",
        { files: idsData, companyID: bodyData.companyID }
      );
     
      if (response.data.status) {
        showAlert("Documents Assigned Successfully.", "success");
        // dispatch(addMsDocument(response.data.result));
        // removeAllFromShare()
      }
      setCompanyID("");
    } catch (error) {
      console.log(error)
    } finally {
      setCompanyID("");
    }
  };
  const removeAllDocument = async (item) => {
    if (!(await DeleteAlert())) return;
    setLoading(true);
    try {
      let ids = [];
      selectedForShare.filter(item => {
        ids.push(item?._id);
      })
      await api.post(
        `/management-system/deleteMSBulk`, { ids: ids }
      ).then(response => {

        setLoading(false)
        setPage(1)
        fetchDocuments()
        removeAllFromShare()
        showAlert("Deleted documents successfully", "success");
      });

    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setDeleteLoading(false);
      //(message);
      showAlert(message, "danger");
      setDeleteLoading(false);
    }
  };
  return {
    documents,
    stats,
    page,
    limit,
    loading,
    deleteLoading,
    addLoading,
    docData,
    setDocData,
    setPage,
    showAddModal,
    setShowAddModal,
    AddNewDocument,
    fetchDocuments,
    changeDocument,
    removeDocument,
    modalName,
    setModalName,
    deleteLoading,
    setLoading,
    addFolderLoading,
    selectedCategories,
    setSelectedCategories,
    selectedNature,
    setselectedNature,
    catFilter,
    setCatFilter,
    natureFilter,
    setNatureFilter,
    addLoading,
    setAddLoading,
    addForCompany,
    companyID,
    setLimit,
    removeAllDocument
  };
};
