import React, { useContext, useEffect, useState } from 'react'
import Screen from '../../components/Screen'
import { ChecklistContext } from '../../hooks/checklistContext';
import QuestionList from '../checklist/QuestionList';
import useAlert from '../../hooks/useAlert';
import api from '../../app/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { capitalFirstLetter } from '../../config/functions';
import SubmitButton from '../../components/SubmitButton';
import { DeleteAlert, UpdateAlert } from '../../components/Swal Alert';
import FormField from '../../components/FormField';

export default function Default() {
    const [loading, setLoading] = useState(null)
    const { showAlert } = useAlert()
    const [data, setData] = useState({
        title: "",
        questions: [],
    });
    const [question, setQuestion] = useState({
        question: "",
        type: "",
        hintText: "",
        options: [],
        yes: "",
        no: "",
        required: false,
    });
    const location = useLocation()
    const onDublicateQuestiuon = (question, index) => {
        var questions = [...data.questions];
        if (index == undefined) {
            questions.push(question);
        } else {
            questions = [
                ...questions.slice(0, index + 1),
                question,
                ...questions.slice(index + 1),
            ];
        }

        setData({ ...data, questions });
    };
    const navigate = useNavigate()
    const newForm = async () => {
        setLoading("new");
        try {
            const response = await api.post("/form/add", { ...data });
            console.log(response)
            //   dispatch(addChecklist(response.data));
            setLoading(false);
            setData({
                title: "",
                questions: [],
            });
            showAlert("New checklist added", "success");
            navigate("/form-list");
        } catch (err) {
            const message = err.response
                ? err.response.data?.message
                    ? err.response.data?.message
                    : err.response.data
                : err;
            console.log(message)
            showAlert(capitalFirstLetter(message), "danger");
            setLoading(false);
        }
    };
    const onAddQuestiuon = (
        e,
        sortIndex,
        setSortIndex,
        editIndex,
        setEditIndex
    ) => {
        e.preventDefault();
        var questions = [...data.questions];
        if (editIndex || editIndex == 0) {
            questions.splice(editIndex, 1);
            setEditIndex(undefined);
        }
        if (sortIndex) {
            questions.splice(sortIndex - 1, 0, question);
            setSortIndex(questions.length + 1);
        } else {
            questions.push(question);
        }
        setData({ ...data, questions });
        setQuestion({
            question: "",
            type: "",
            hintText: "",
            options: [],
            yes: "",
            no: "",
            required: false,
        });
    };
    const changeForm = async () => {
        if (!(await UpdateAlert())) return;
        setLoading("update");
        try {
            await api.put(
                `/form/update/${data._id}`,
                data
            );
            setData({ ...data });
            //   dispatch(updateChecklist({ ...data, status }));
            setLoading(false);
            showAlert(
                "Checklist updated successfully",
                "success"
            );
            return true;
        } catch (err) {
            const message = err.response
                ? err.response.data?.message
                    ? err.response.data?.message
                    : err.response.data
                : err;
            setLoading(false);
            showAlert(capitalFirstLetter(message), "danger");
            setLoading(false);
        }
    };
    const removeForm = async (checklist) => {
        if (!(await DeleteAlert())) return;
        setLoading("delete");
        try {
            const response = await api.delete(
                `/checklist/delete/${checklist ? checklist._id : data._id}`
            );
            //   dispatch(deleteChecklist(response.data));
            setLoading(false);
            if (!checklist) navigate("/checklists");
            showAlert("Checklist removed successfully", "success");
        } catch (err) {
            const message = err.response
                ? err.response.data?.message
                    ? err.response.data?.message
                    : err.response.data
                : err;
            showAlert(message, "danger");
            setLoading(false);
        }
    };
    const [sortOrder, setSortOrder] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const titleField = {
        value: data?.title,
        setValue: (value) => setData({ ...data, title: value }),
        placeholder: 'Enter form title',
        type: 'text',
        id: 'formTitle'
    }
    useEffect(() => {
        if (location.state) setData({ title: location.state?.title, questions: location.state?.questions })
    }, [])

    return (
        <Screen
            loading={loading == "initial"}
            flow={[
                { label: `Add form`, to: `/add-form` },
                { label: "Home", to: "/" },
                { label: `Add form`, to: `/add-form` },
            ]}>

            <div className="d-flex justify-content-end mb-3 gap-3">
                {
                    <>
                        <SubmitButton
                            title={"Save Form"}
                            loading={loading == "new" || loading == "update"}
                            onClick={() =>
                                location.state?.update
                                    ? changeForm()
                                    : newForm()
                            }
                            color={"success"}
                            loaderColor={"white"}
                            disabled={!data.title || data.questions?.length < 1}
                        />
                        {(
                            <SubmitButton
                                icon={<i className="bi bi-trash3"></i>}
                                loading={loading == "delete"}
                                onClick={() => removeForm()}
                                color={"danger"}
                                loaderColor={"white"}
                                disabled={false}
                            />
                        )}
                    </>
                }
            </div>
            <div className="col-md-12 col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Form Title</h5>
                        <FormField item={titleField} />
                    </div>
                </div>
            </div>
            <div className="row">
                {/* New Question */}
                {/* Question List */}
                <div className="col-xl-12">
                    <QuestionList
                    isForm={true}
                        questions={data?.questions}
                        setQuestion={setQuestion}
                        setQuestions={(questions) => setData({ ...data, questions })}
                        setData={setData}
                        setEditIndex={setEditIndex}
                        setSortOrder={setSortOrder}
                        question={question}
                        onAddQuestiuon={onAddQuestiuon}
                        sortOrder={sortOrder}
                        editIndex={editIndex}
                        onDublicateQuestiuon={onDublicateQuestiuon}
                    />
                </div>
            </div>
        </Screen>
    )
}
