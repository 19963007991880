import { Checkbox } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { keys } from "../../config/keys";
import { PoliciesContext } from "../../hooks/policiesContext";
import useAuth from "../../hooks/useAuth";
import useManagementSystem from "../../hooks/useManagementSystem";
import { convertToLocal } from "../../config/functions";

const FilesList = ({
  setModalName,
  setShowAddModal,
  setDocData,
  page,
  limit,
  setPage,
  setSelectedCategories,
  setSelectedNature,
  setLimit,
  typeFilter,
  filter,
  setfilter,
  removeAllDocument
}) => {
  const { msDocuments, totalMsDocs } = useSelector((state) => state.msDocuments);
  const { removeDocument, deleteLoading } = useManagementSystem();
  const { isAdmin } = useAuth();
  const {
    selectedForShare,
    addForShare,
    removeFromShare,
    removeAllFromShare,
    addAllToShare,
  } = useContext(PoliciesContext);
  const onView = async (item) => {
    window.open(`${keys.BASE_URL}/management-system/download?filename=${item.url?.substring(8, item.url?.length)}`, "_blank");
  };

  const onHandleMoadal = (item) => {
    setModalName("Update Document");
    setShowAddModal(true);
    setDocData(item);
    setSelectedNature(item.natureID)
    setSelectedCategories(item.categoryID)

  };

  const labels = [
    isAdmin ? (
      <Checkbox
        checked={selectedForShare.length == msDocuments.length}
        onChange={async (e) => {
          if (e.target.checked) {
            addAllToShare(msDocuments ? msDocuments : []);
          } else {
            removeAllFromShare();
          }
        }}
      />
    ) : (
      <Checkbox
        checked={selectedForShare.length == msDocuments.length}
        onChange={async (e) => {
          if (e.target.checked) {
            addAllToShare(msDocuments ? msDocuments : []);
          } else {
            removeAllFromShare();
          }
        }}
      />
    ),
    "Name",
    "Type",
    "Date Added",
    "File",
    isAdmin && "Actions",
  ].filter((item) => item);
  const handleFileType = (item) => {
    let type = item?.url?.split(".");
    return type && type.length > 1 ? type[1] : ''
  }
  const data = useMemo(() => {
    if (!msDocuments || msDocuments.length === 0) return [];
    return msDocuments.filter((item) => {
      // Apply text search filter based on 'name'
      if (filter.search != '') {
        return item?.name?.toLowerCase().includes(filter.search.toLowerCase());
      }
      return true;  // No search text provided, return all items
    }).map((item) => {
      return {
        data: item,
        fields: [
          isAdmin ? (
            <Checkbox
              checked={selectedForShare.includes(item)}
              onChange={async (e) => {
                if (e.target.checked) {
                  addForShare(item);
                } else {
                  removeFromShare(item);
                }
              }}
            />
          ) : (
            <Checkbox
              checked={selectedForShare.includes(item)}
              onChange={async (e) => {
                if (e.target.checked) {
                  addForShare(item);
                } else {
                  removeFromShare(item);
                }
              }}
            />
          ),
          item?.name,
          <td>{handleFileType(item)}</td>,
          convertToLocal(item?.createdAt),
          <button
            onClick={() => onView(item)}
            // href={`${keys.BASE_LOCAL_URL}/${item.url}`}
            className="btn btn-sm btn-success"
          // download
          >
            Open
          </button>,
          isAdmin && (
            <div className="d-flex gap-3">
              {!item.companyID && <i
                className="bi bi-pencil-square text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => onHandleMoadal(item)}
              ></i>}
              {deleteLoading?._id == item?._id ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <Loader loading={deleteLoading} color={"red"} />
                </div>
              ) : (
                <i
                  className="bi bi-trash text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeDocument(item)}
                ></i>
              )}
            </div>
          ),
        ].filter((it) => it),
      };
    }).filter((it) => {
      if (typeFilter == 'pdf') {
        if (handleFileType(it.data) == 'pdf') {
          return it
        }
      } else if (typeFilter == 'docx') {
        if (handleFileType(it.data) == 'docx') {
          return it
        }
      } else {
        return it
      }
    })
  }, [typeFilter, msDocuments, filter, selectedForShare, deleteLoading])
  return (
    <>
      <Table
        labels={labels}
        data={data}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        filter={filter}
        setfilter={setfilter}
        totalData={totalMsDocs}
        removeAllDocument={removeAllDocument}
      />
    </>
  );
};

export default FilesList;
