import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// import useChecklist from '../hooks/useChecklist';

import useAuth from '../hooks/useAuth';
import Loader from './Loader';
import UserModal from './UserModal';
import appContext from '../app/contextState/context';
import { ChecklistContext } from '../hooks/checklistContext';
import CompanyModal from "./companyModel";
import api from '../app/api';
import useAlert from '../hooks/useAlert';
const ChecklistCard = ({ template, data, type, onClick, select }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = useContext(appContext);
    const { handleChecklistMultiple, loading } = state;
    const { removeChecklist } = useContext(ChecklistContext)
    const { isAdmin, isCompany } = useAuth()
    const [show, setShow] = useState();
    const [showCompanyModel, setShowCompanyModel] = useState();
    const onShow = () => setShow(data);
    const onAssignShow = () => setShowCompanyModel(data);
    const onHide = () => setShow(undefined);
    const {showAlert} = useAlert()
    const [assignLoading, setAssignLoading] = useState(null)
    const total = type != 'task' ? data?.questions?.length : data?.tasks?.filter(item => item.status == 'Approved').length
    const pending = type != 'task' ? undefined : data?.tasks?.filter(item => (item.status == 'Pending' && item.userID?._id == localStorage.getItem('_id'))).length
    const assignChecklist = async (companyID) => {
        setAssignLoading(companyID)
        // setLoading("new");
        try {
            const response = await api.post("/checklist/assignChecklist", { ...data, companyID });
            showAlert("Checklist assigned", "success");
            setShowCompanyModel(null)
            setAssignLoading(companyID)
        } catch (err) {
            const message = err.response
                ? err.response.data?.message
                    ? err.response.data?.message
                    : err.response.data
                : err;
            showAlert(message, "danger");
            setShowCompanyModel(null)
            setAssignLoading(companyID)
        }
    };
    return (
        <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in"
            data-aos-delay="200">
            <div className="icon-box">
                {select && <><input type="checkbox" onChange={(e) => { handleChecklistMultiple(e, data) }} /> Select</>}
                {
                    !pending ? null :
                        <div className='d-flex justify-content-end' style={{ position: 'absolute', width: '75%' }}>

                            <span
                                className='d-flex align-items-center justify-content-center p-2 bg-warning rounded-5'
                                style={{ width: 30, height: 30, color: 'white' }}
                            >
                                {pending}
                            </span>
                        </div>
                }
                <p className='d-flex w-100 justify-content-center text-info'>{
                    isAdmin ? data.companyID?.name :
                        isCompany
                            ? data.userID?.branchID ? data.userID?.branchID?.name : data.userID?.name
                            : null
                }</p>

                <div className="icon"><i className="bi bi-card-checklist"></i></div>

                <h4 className="title">{data?.title}</h4>
                <p className="description mb-4">
                    {total} &nbsp;
                    {type == 'task' ? 'Tasks' : 'Questions'} {type == 'task' ? 'Submitted' : 'Added'}
                </p>
                <div className='mb-2 row'>
                    {
                        type == 'new' && !template && !!data?.companyID && !isAdmin ?
                            <div className='col-6 col-sm-6 col-md-6 col-lg-12'>
                                <button
                                    className='btn btn-sm rounded-5 btn-outline-primary w-100 mt-2'
                                    onClick={onShow}
                                >
                                    <Loader loading={loading} center color={'white'}>
                                        Assign/Un-assign Task
                                    </Loader>
                                </button>
                            </div>
                            : null
                    }
                    {
                        type == 'new' && !template && isAdmin ?
                            <div className='col-6 col-sm-6 col-md-6 col-lg-12'>
                                <button
                                    className='btn btn-sm rounded-5 btn-outline-primary w-100 mt-2'
                                    onClick={onAssignShow}
                                >
                                    <Loader loading={loading} center color={'white'}>
                                        Assign Checklist
                                    </Loader>
                                </button>
                            </div>
                            : null
                    }
                    {
                        type == 'new' && !template && !!data?.companyID && !isAdmin ?
                            <div className='col-6 col-sm-6 col-md-6 col-lg-12'>
                                <button
                                    className='btn btn-sm rounded-5 btn-outline-primary w-100 mt-2'
                                    onClick={() => {
                                        navigate(`/task${location.search}`, {
                                            state: {
                                                ...data,
                                                fresh: true
                                            }
                                        })
                                    }}
                                >
                                    Submit Task
                                </button>
                            </div>
                            : null
                    }
                </div>
                {
                    type == 'new'
                        ? <Link
                            to={template ? '/new-checklist' : '/new-checklist?update=true'}
                            state={{ data, ...(!template ? { update: true } : {}) }}
                        >
                            {template ? 'Create' : 'View Checklist'}
                        </Link>
                        : type == 'task'
                            ?
                            <button
                                className='btn btn-sm rounded-5 btn-outline-primary w-100 mt-2'
                                onClick={onClick}
                            >
                                View Task
                            </button>
                            : <button className='btn rounded-5 btn-sm btn-outline-primary w-100' onClick={() => removeChecklist(data)}>
                                <Loader loading={loading == 'delete'} center color={'white'}>
                                    Remove
                                </Loader>
                            </button>
                }
            </div>
            {show && <UserModal
                show={show}
                checklist={data}
                handleClose={onHide}
            />}
            {showCompanyModel && <CompanyModal
                show={showCompanyModel}
                handleClose={setShowCompanyModel}
                title={"Assign Checklist"}
                onClick={assignChecklist}
                btnLoading={assignLoading}
            />}
        </div>
    )
}

export default ChecklistCard;