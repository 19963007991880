import { createSlice } from "@reduxjs/toolkit";

const crmTaskSlice = createSlice({
  name: "contacts",
  initialState: {
    contacts: [],
  },
  reducers: {
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
    updateContact: (state, action) => {
        state.contacts = state.contacts.map(item => item._id == action.payload._id ? action.payload : item);
    },
    deleteContact: (state, action) => {
        state.contacts = state.contacts.filter(item => item._id != action.payload._id);
    },
    addContact: (state, action) => {
        state.contacts = [...state.contacts, action.payload];
    },
  },
});

export const { setContacts, updateContact,deleteContact, addContact } = crmTaskSlice.actions;

export default crmTaskSlice.reducer;
