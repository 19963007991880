import { useEffect } from "react";

import io from "socket.io-client";
import { keys } from "../config/keys";

import useAuth from "./useAuth";
import useNotification from "./useNotification";
import useChat from "./useChat";

const useSocket = () => {
  const { token, onPermission } = useAuth();
  const { newNotification } = useNotification();
  const { createMessage, changeMessagesStatus, changeChat } = useChat();

  const socket = io(`${keys.BASE_URL}/socket`, {
    autoConnect: false,
    query: { token },
  });

  useEffect(() => {
    const onConnect = () => {};
    const onDisconnect = () => {};
    const onNotification = (notification) => {
      console.log('in notification')
      newNotification(notification)
    };

    if (token) {
      if (!socket.connected) socket.connect();
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("notification", onNotification);
      socket.on("message", (data)=>{
        console.log(data)
        createMessage(data)
      });
      socket.on("messageStatus", changeMessagesStatus);
      socket.on("chat", changeChat);
      socket.on("permission", onPermission);
    } else socket.disconnect();
    return () => {
      socket.disconnect();
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("notification", onNotification);
      socket.off("message", createMessage);
      socket.off("messageStatus", changeMessagesStatus);
      socket.off("chat", changeChat);
      socket.off("permission", onPermission);
    };
  }, [token]);
};

export default useSocket;
