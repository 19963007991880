import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

// import useChecklist from '../hooks/useChecklist';

import { PoliciesContext } from '../hooks/policiesContext';
import useAuth from '../hooks/useAuth';

const FormCard = ({ data }) => {
    const state = useContext(PoliciesContext);
    const { selectedForShare, addForShare, removeFromShare  } = state;
    const { isAdmin, isCompany } = useAuth()

    const total = data?.questions?.length

    return (
        <div className="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in"
            data-aos-delay="200">
            <div className="icon-box">
                <p className='d-flex w-100 justify-content-center text-info'>{
                    isAdmin || isCompany ? data.addedBy?.name :
                        null
                }</p>

                <div className="icon"><i className="bi bi-card-checklist"></i></div>

                <h4 className="title">{data?.title}</h4>
                <p className="description mb-4">
                    {total} &nbsp;
                    {'Questions'} {'Added'}
                </p>
                <Link
                className='mb-2'
                    to={'/share'}
                    onClick={()=>addForShare(data)}
                    state={{
                        selectedForShare: [data],
                        isForm: true
                      }}
                >
                    {'Share Form'}
                </Link>
                <Link
                    to={'/add-form'}
                    state={data}
                >
                    {'View Form'}
                </Link>


            </div>
        </div>
    )
}

export default FormCard;