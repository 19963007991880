import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Table from "../../components/Table";
import { PoliciesContext } from "../../hooks/policiesContext";
import useAuth from "../../hooks/useAuth";
const FilesList = () => {
  const { isAdmin, isCompany } = useAuth();
  const { selectedForShare, removeFromShare } = useContext(PoliciesContext);
  const location = useLocation();
  const labels = [
    "#",
    location.state?.isDocument || location.state?.isManagementSystem ? "Name" : "Title",
    location.state?.isDocument  ? "Size" : location.state?.isManagementSystem ||location.state?.isForm ? "Created At" : "Last Reviewed",
    (isAdmin || isCompany) && "Actions",
  ].filter((item) => item);
  const data = selectedForShare
    ? selectedForShare.map((item, index) => {
        return {
          data: item,
          fields: [
            index + 1,
            item?.title || item?.name,
            item?.lastReviewed?.substring(0, 10) || item.size || item.createdAt?.substring(0,10),
            (isAdmin || isCompany) && (
              <div className="d-flex gap-3">
                <i
                  className="bi bi-trash text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeFromShare(item)}
                ></i>
              </div>
            ),
          ].filter((it) => it),
        };
      })
    : [];
  return (
    <div className="col-md-12 col-lg-6">
      <Table labels={labels} data={data} heightControl={true} />
    </div>
  );
};

export default FilesList;
