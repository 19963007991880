import React, { useState } from 'react'
import Screen from '../../components/Screen'
import ChecklistCard from '../../components/ChecklistCard';
import useAlert from '../../hooks/useAlert';
import api from '../../app/api';
import EmptyData from '../../components/EmptyData';
import { capitalFirstLetter } from '../../config/functions';
import Pagination from '../../components/Pagination';
import { useEffect } from 'react';
import FormCard from "../../components/formCard"
export default function Default() {
    const [loading, setLoading] = useState(null)
    const [forms, setForms] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const { showAlert } = useAlert()
    const params = {
        page,
        limit,
    };
    const fetchForms = async () => {
        setLoading("initial");
        try {
            const response = await api.get(`/form`, { params, addedBy: localStorage.getItem('_id') });
            //   dispatch(setChecklist(response.data));
            if (response.data.status) {
                setForms(response.data.result)
            } else {
                setForms([])
            }
            setLoading(false);
        } catch (err) {
            const message = err.response
                ? err.response.data?.message
                    ? err.response.data?.message
                    : err.response.data
                : err;
            showAlert(capitalFirstLetter(message), "danger");
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchForms()
    }, [])

    return (
        <Screen
            loading={loading == "initial"}
            flow={[
                { label: `Forms`, to: `/form-list` },
                { label: "Home", to: "/" },
                { label: `Forms`, to: `/form-list` },
            ]}>
            <section className="section" id="checklist">
                <EmptyData data={forms} onReload={() => fetchForms(params)}>
                    <div className="row icon-boxes">
                        {forms?.map((item, index) => {
                            return (
                                <FormCard
                                    data={item}
                                    key={index}
                                    type="new"
                                    select={true}
                                />
                            );
                        })}
                    </div>
                </EmptyData>
                <Pagination
                    data={forms}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    setLimit={setLimit}
                />
            </section>
        </Screen>
    )
}
